import { fabric } from 'fabric'
import gifFrames from 'gif-frames'
import * as storage from 'idb-keyval'
import { requestIdleCallback } from '../helpers'

const gifCache = new Map()

const idle = () => new Promise((resolve) => requestIdleCallback(resolve, { timeout: 100 }))

const Gif = fabric.util.createClass(fabric.Image, {
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,
  spriteIndex: 0,

  initialize: function (_, options) {
    this.id = options.id
    this.type = 'gif'
    this.frames = options.frames
    this.src = options.src
    this.previewSrc = options.previewSrc
    this.width = options.width
    this.height = options.height
    this.frameTime = this.frames[0].delay
    this.animation = { type: null }

    this.scaleToWidth(200)
    this.createSpriteImages()
    this.callSuper('initialize', null, options)

    this.on({ removed: () => this.stop() })
    this.setControlsVisibility({ mt: false, ml: false, mr: false, mb: false })
    this.play()
  },

  createSpriteImages: function () {
    this.spriteImages = []
    for (const frame of this.frames) {
      const tmpImg = fabric.util.createImage()
      tmpImg.src = frame.url
      this.spriteImages.push(tmpImg)
    }
  },

  _render: function (ctx) {
    ctx.drawImage(this.spriteImages[this.spriteIndex], -this.width / 2, -this.height / 2)
  },

  _updateFrame: function () {
    this.spriteIndex++
    if (this.spriteIndex === this.spriteImages.length) this.spriteIndex = 0
    this.frameTime = this.frames[this.spriteIndex].delay
    if (this.canvas?.getObjects().length) this.canvas.requestRenderAll()
    this.animTimeout = setTimeout(this._updateFrame.bind(this), this.frameTime)
  },

  play: function () {
    this.stop()
    this.animTimeout = setTimeout(this._updateFrame.bind(this), this.frameTime)
  },

  stop: function () {
    clearTimeout(this.animTimeout)
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      src: this.src,
      previewSrc: this.previewSrc,
      animation: this.animation,
      type: this.type,
      meta: this.meta,
    })
  },
})

Gif.fromObject = function (object, callback) {
  Gif.createGif(object)
    .then((gif) => {
      callback(gif)
    })
    .catch(() => callback(null, true))
}

const prepareGifFrames = async (src) => {
  const cachedFrames = await storage.get(src).catch(() => null)
  if (cachedFrames) return cachedFrames

  let frameData = await gifFrames({ url: src, frames: 'all', outputType: 'canvas' })
  frameData = await Gif.renderCumulativeFrames(frameData)

  const frames = []
  for (const frame of frameData) {
    frames.push({
      url: frame.getImage().toDataURL('image/png'),
      delay: frame.frameInfo.delay * 10,
    })
    await idle()
  }

  const retval = { width: frameData[0].getImage().width, height: frameData[0].getImage().height, frames }
  storage.set(src, retval).catch(() => {})
  return retval
}

Gif.createGif = async function (options) {
  const gifSrc = options.previewSrc || options.src
  const { width, height, frames } = gifCache.has(gifSrc) ? gifCache.get(gifSrc) : await prepareGifFrames(gifSrc)

  if (!gifCache.has(gifSrc)) gifCache.set(gifSrc, { width, height, frames })

  const gif = new fabric.Gif(null, { frames, width, height, ...options })
  return gif
}

Gif.renderCumulativeFrames = async (frameData) => {
  if (frameData.length === 0) return frameData
  const previous = new fabric.StaticCanvas(null, { enableRetinaScaling: false }).getElement()
  const previousContext = previous.getContext('2d')
  const current = new fabric.StaticCanvas(null, { enableRetinaScaling: false }).getElement()
  const currentContext = current.getContext('2d')
  const firstFrameCanvas = frameData[0].getImage()

  previous.width = firstFrameCanvas.width
  previous.height = firstFrameCanvas.height
  current.width = firstFrameCanvas.width
  current.height = firstFrameCanvas.height

  for (const frame of frameData) {
    previousContext.clearRect(0, 0, previous.width, previous.height)
    previousContext.drawImage(current, 0, 0)

    const canvas = frame.getImage()
    const context = canvas.getContext('2d')
    currentContext.drawImage(canvas, 0, 0)
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.drawImage(current, 0, 0)

    const { frameInfo } = frame
    if (frameInfo.disposal === 2) {
      currentContext.clearRect(frameInfo.x, frameInfo.y, frameInfo.width, frameInfo.height)
    } else if (frameInfo.disposal === 3) {
      currentContext.clearRect(0, 0, current.width, current.height)
      currentContext.drawImage(previous, 0, 0)
    }
    frame.getImage = () => canvas

    await idle()
  }
  return frameData
}

Gif.dropCache = () => gifCache.clear()

Gif.async = true

export default Gif
