import { fabric } from 'fabric'
import { getFontFamilyWithFallback } from './fontUtils'

const paddingPercent = 0.1
const bulletOffset = 30
const baseFontSize = 40

const List = fabric.util.createClass(fabric.Textbox, {
  type: 'list',
  objectCaching: false,
  listCounter: 0,

  initialize: function (text, options) {
    this.id = options.id
    this.text = text
    this.fontFamily = options.fontFamily
    this.fontSize = options.fontSize
    this.left = options.left
    this.top = options.top
    this.width = options.width
    this.scaleX = options.scaleX
    this.scaleY = options.scaleY
    this.listType = options.listType
    this.listBullet = options.listBullet
    this.fill = options.fill
    this.styles = options.styles
    this.padding = this.width * this.scaleX * paddingPercent
    this.animation = options.animation
    this.meta = options.meta

    this.callSuper('initialize', text, options)
    this.setControlsVisibility({ ml: true, mr: true, mtr: false })
    this.on({
      scaling: ({ transform }) => this.set({ padding: this.width * transform.target.scaleX * paddingPercent }),
    })
  },

  _renderTextLine: function (method, ctx, line, left, top, lineIndex) {
    const style = this.getCompleteStyleDeclaration(lineIndex, 0)
    const bullet = this.listType === 'unordered' ? [this.listBullet] : [this.listCounter + 1 + '.']
    const bulletMetrics = ctx.measureText(bullet)
    const bulletLeft = left - style.fontSize - bulletMetrics.width + bulletOffset * (style.fontSize / baseFontSize)

    if (line && this.isStartOfWrapping(lineIndex)) {
      this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex)
      this.listCounter++
    }

    if (lineIndex === this.textLines.length - 1) {
      this.listCounter = 0
    }

    this._renderChars(method, ctx, line, left, top, lineIndex)
  },

  isStartOfWrapping: function (lineIndex) {
    const text = this.text
    const textLines = this.textLines
    const unwrappedLines = text.split('\n')
    const results = []
    let indexesOfLines = []
    let unwrappedLineIndex = 0

    for (let i = 0; i < textLines.length; i++) {
      const line = textLines[i]
      if (indexesOfLines.length) {
        indexesOfLines.push(i)
        const linesText = indexesOfLines.map((index) => textLines[index]).join(' ')
        if (linesText === unwrappedLines[unwrappedLineIndex]) {
          indexesOfLines.forEach((index, i) => {
            if (i === 0) results[index] = true
            else results[index] = false
          })
          indexesOfLines = []
          unwrappedLineIndex += 1
        }
        continue
      }

      if (line === unwrappedLines[unwrappedLineIndex]) {
        results[i] = true
        unwrappedLineIndex += 1
      } else {
        indexesOfLines.push(i)
      }
    }

    return results[lineIndex]
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      listType: this.listType,
      listBullet: this.listBullet,
      styles: this.styles,
      animation: this.animation,
      fontFamily: this.fontFamily.split(',')[0],
      meta: this.meta,
    })
  },
})

List.fromObject = async function (object, callback) {
  if (!object.fontFamily.includes(',')) object.fontFamily = getFontFamilyWithFallback(object.fontFamily)
  return callback(new fabric.List(object.text, object))
}

export default List
