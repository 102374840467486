import { Tag, Row, Col } from 'antd'

import Icon from '../../Icon'
import { CATEGORIES } from '../constants'

export const HeaderRow = (props) => {
  const { isVideoCreating, handleCategoryClick, setIsNewVideoModalOpen, visibleCategory } = props

  const handleTemplateClick = () => handleCategoryClick(CATEGORIES.regular)
  const handlePDFClick = () => {
    setIsNewVideoModalOpen(true)
    handleCategoryClick(CATEGORIES.pdf)
  }

  const handleStoryClick = () => {
    setIsNewVideoModalOpen(true)
    handleCategoryClick(CATEGORIES.story)
  }
  const handleAvatarClick = () => {
    handleCategoryClick(CATEGORIES.avatars)
  }

  const rowStyle = { marginBottom: 30 }

  return (
    <Row gutter={[16, 16]} className="option-cards-row" style={rowStyle}>
      <Col className="create-video-modal-card">
        <div
          className={`option-card all ${visibleCategory === CATEGORIES.regular ? 'active' : ''} ${
            isVideoCreating ? 'disable' : ''
          }`}
          onClick={handleTemplateClick}
        >
          <p>From template</p>
          <div className="icon-circle">
            <Icon name="blank" />
          </div>
        </div>
      </Col>
      <Col className="create-video-modal-card">
        <div
          className={`option-card ${visibleCategory === CATEGORIES.story ? 'active' : ''} ${
            isVideoCreating ? 'disable' : ''
          }`}
          onClick={handleStoryClick}
        >
          <Tag color="#C971FF" className="card-tag positioned-absolute">
            Beta
          </Tag>
          <p>AI storyboard</p>
          <div className="icon-circle">
            <Icon name="clipboard" />
          </div>
          <span className="option-card-hint" style={{ paddingLeft: '50px', paddingRight: '50px', lineHeight: '1.3' }}>
            Type a topic and get a video in minutes
          </span>
        </div>
      </Col>
      <Col className="create-video-modal-card">
        <div className="option-card" onClick={handleAvatarClick}>
          <p>Start with an avatar</p>
          <div className="icon-circle">
            <Icon name="users" />
          </div>
          <span className="option-card-hint" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
            Create blank video with an avatar
          </span>
        </div>
      </Col>

      <Col className="create-video-modal-card">
        <div
          className={`option-card ${visibleCategory === CATEGORIES.pdf ? 'active' : ''} ${
            isVideoCreating ? 'disable' : ''
          }`}
          onClick={handlePDFClick}
        >
          <Tag color="#31353C" className="card-tag positioned-absolute">
            New
          </Tag>
          <p>From presentation</p>
          <div className="icon-circle">
            <Icon name="upload_file" />
          </div>
          <span className="option-card-hint" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
            Upload PPTX/PDF file and turn it into a video
          </span>
        </div>
      </Col>
    </Row>
  )
}
