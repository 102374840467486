const stripEmoji = (text) =>
  text.replace(
    /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{1F900}-\u{1F9FF}|\u{1F1E0}-\u{1F1FF}|\u{1F191}-\u{1F251}|\u{1F004}]/gu,
    '',
  )

const clearTextWithoutTrim = (text) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  stripEmoji(
    text
      .replaceAll('…', '. ')
      .replaceAll('@', ' ')
      .replaceAll('\r\n', '\n')
      .replaceAll('\r', '\n')
      .replaceAll('\t', '')
      .replace(/[<>*#^~`|/\\]/gm, ' ')
      .replace(/&/gm, 'and ')
      .replace(/(\[.+\])/gm, '')
      .replace(/ ([.,;:!?]) ?/gm, '$1')
      .replace(/^[.,;!?]+/, '')
      .replace(/\u00A0/gim, ' ') // convert &nbsp; to normal ASCII space
      .replace(/\s{2,}/gm, ' '),
  )

const clearText = (text) => clearTextWithoutTrim(text).trim()

const sanitizeHTML = (html) => html.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('&nbsp;', ' ')

const checkHtmlCanBeParsed = (html) => {
  const hasNewLines = html.includes('\n\n') || /<br/gi.test(html)
  const hasParagraphs = html.match(/<p/gi)?.length > 1
  const hasHeaders = /<h\d|<strong|<b/gi.test(html) && (!html.includes('<h2 data-name="header"') || hasNewLines)

  return hasNewLines || hasParagraphs || hasHeaders
}

const convertHtmlToPlainText = (html) => {
  const div = document.createElement('div')
  div.innerHTML = html
  return clearText(sanitizeHTML(div.textContent || div.innerText || ''))
}

const getContentFromClipboard = (e) => {
  let type = 'html'
  let content = e.clipboardData.getData('text/html')
  if (!content) {
    content = e.clipboardData.getData('text/plain')
    type = 'text'
  }

  return { content, type }
}

export { convertHtmlToPlainText, getContentFromClipboard, checkHtmlCanBeParsed, clearText }
