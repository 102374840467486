import Scrollbars from 'react-custom-scrollbars'

import { CreateOptions } from './createOptions'
import { OptionComponent } from './optionComponent'

export const CreateStoryVideo = ({
  option,
  gptValue,
  setOption,
  setGPTValue,
  htmlToVideo,
  pastedContent,
  setPastedContent,
  isVideoCreating,
  setIsGenerationProgressVisible,
  setHtmlToVideo,
  setIsVideoCreating,
  generationProgress,
  handleAfterCreateVideo,
}) => {
  return (
    <Scrollbars className="template-scrollbar story">
      <div className="story-card">
        <h2 className="story-title">Effortlessly create videos from scripts with our smart editor</h2>
        <p className="story-subtitle">Video creation options</p>
        <CreateOptions currentOption={option} setOption={setOption} />
        <OptionComponent
          currentOption={option}
          gptValue={gptValue}
          setGPTValue={setGPTValue}
          isVideoCreating={isVideoCreating}
          htmlToVideo={htmlToVideo}
          setHtmlToVideo={setHtmlToVideo}
          setIsGenerationProgressVisible={setIsGenerationProgressVisible}
          setIsVideoCreating={setIsVideoCreating}
          generationProgress={generationProgress}
          pastedContent={pastedContent}
          setPastedContent={setPastedContent}
          handleAfterCreateVideo={handleAfterCreateVideo}
        />
      </div>
    </Scrollbars>
  )
}
