import { useEffect, useRef, useState, useMemo } from 'react'
import { Input, Dropdown, Typography, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { outlineTopics } from '../constants'
import Icon from '../../../components/Icon'
import { useSlideDuration } from '../../../hooks/useSlideDuration'

export const TopicInput = ({
  video,
  gptLoading,
  isBlankVideo,
  gptInputValue,
  setGptInputValue,
  setGptLoading,
  applyVideoEnhancement,
  handleSubmitGptInput,
  isEditorBusy,
}) => {
  const gptInputDropdownRef = useRef(null)
  const gptInputRef = useRef(null)
  const gptInputInnerRef = useRef(null)
  const [isOpenGptDropdown, setIsOpenGptDropdown] = useState(false)

  const { getApproxDuration } = useSlideDuration({})

  const handleClickGptMenu = async ({ key }) => {
    setIsOpenGptDropdown(false)
    if (['marketing', 'learning-development', 'how-to'].includes(key)) {
      setGptInputValue(outlineTopics[key])
      gptInputInnerRef.current.focus()
    } else {
      setGptLoading({ key, message: 'Rewriting text', isLoading: true })
      await applyVideoEnhancement(key)
      setGptLoading({ isLoading: false })
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        gptInputDropdownRef.current &&
        !gptInputDropdownRef.current.contains(event.target) &&
        !gptInputRef.current.contains(event.target)
      ) {
        setIsOpenGptDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [gptInputDropdownRef])

  const inputGptMenuItems = useMemo(
    () =>
      isBlankVideo
        ? [
            {
              label: (
                <span className="menu-item-label">
                  <Icon name="notes" />
                  <span>Marketing video</span>
                </span>
              ),
              key: 'marketing',
            },
            {
              label: (
                <span className="menu-item-label">
                  <Icon name="mortarboard" />
                  <span>L&D video</span>
                </span>
              ),
              key: 'learning-development',
            },
            {
              label: (
                <span className="menu-item-label">
                  <Icon name="play_presentation" />
                  <span>How to video</span>
                </span>
              ),
              key: 'how-to',
            },
          ]
        : [
            {
              label: (
                <Tooltip
                  title="GPT will generate any missed content required to create slides: headers, subheaders, speech text and even images."
                  placement="right"
                >
                  <span className="menu-item-label">
                    <Icon name="text_fields" />
                    <span>Add missing content</span>
                  </span>
                </Tooltip>
              ),
              key: 'add-missed-texts',
            },
            {
              label: (
                <Tooltip
                  title="Each slide duration limit is 60 seconds. You can shorten your speech using GPT to fit this limit."
                  placement="right"
                >
                  <span className="menu-item-label">
                    <Icon name="connection" />
                    <span>Shorten speech to fit slide limit</span>
                  </span>
                </Tooltip>
              ),
              key: 'fit-speech',
              show: (slides) => slides.some((slide) => getApproxDuration(slide) > 40),
            },
            {
              label: (
                <Tooltip title="Make your speech sound more professional" placement="right">
                  <span className="menu-item-label">
                    <Icon name="office_bag" />
                    <span>More professional</span>
                  </span>
                </Tooltip>
              ),
              key: 'professional',
            },
            {
              label: (
                <Tooltip title="Make your speech sound more engaging" placement="right">
                  <span className="menu-item-label">
                    <Icon name="light_bulb" />
                    <span>More engaging</span>
                  </span>
                </Tooltip>
              ),
              key: 'engaging',
            },
            {
              label: (
                <Tooltip title="Make your speech sound happier" placement="right">
                  <span className="menu-item-label">
                    <Icon name="happy_face" />
                    <span>Sound happier</span>
                  </span>
                </Tooltip>
              ),
              key: 'happier',
            },
          ],
    [isBlankVideo],
  )

  return (
    <Dropdown
      menu={{
        items: inputGptMenuItems.filter((item) => !Object.hasOwn(item, 'show') || item.show(video.slides)),
        onClick: handleClickGptMenu,
      }}
      open={isOpenGptDropdown && !gptInputValue}
      overlayClassName="input-gpt-dropdown"
      dropdownRender={(node) => <div ref={gptInputDropdownRef}>{node}</div>}
    >
      <div className={`input-gpt ${gptLoading.isLoading ? 'disabled' : ''}`} ref={gptInputRef}>
        {isEditorBusy && (
          <div className="loading-notification">
            <Icon name="robot" />
            <Typography.Text type="secondary">{gptLoading.message || 'Waiting for response'}...</Typography.Text>
          </div>
        )}
        <Input
          ref={gptInputInnerRef}
          value={gptInputValue}
          placeholder={isBlankVideo ? 'Write a topic for your video' : 'Add more slides by topic'}
          size="large"
          onPressEnter={handleSubmitGptInput}
          onChange={(e) => {
            setGptInputValue(e.target.value)
            if (isOpenGptDropdown) setIsOpenGptDropdown(false)
          }}
          disabled={isEditorBusy}
        />
        <Icon name="magic_wand" className="magic-wand-icon" onClick={() => setIsOpenGptDropdown(!isOpenGptDropdown)} />
        {gptLoading.isLoading ? (
          <LoadingOutlined className="submit-icon" />
        ) : (
          <Icon
            name="paper_plane"
            className={`submit-icon ${gptInputValue ? '' : 'disabled'}`}
            onClick={handleSubmitGptInput}
          />
        )}
      </div>
    </Dropdown>
  )
}
