/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Alert, Button, Input, Collapse, Modal, Spin, Progress, Table, Divider } from 'antd'
import { RightOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import { useLocation } from 'react-router'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { formatSeconds } from '../../utils/videos'
import { plansPrices } from '../../data/plans'
import { capitalizeFirstLetter, sleep } from '../../utils/helpers'
import thinkificLogo from '../../assets/images/thinkific-dark.svg'
import './profile.less'
import SuccessPaymentModal from './components/successPaymentModal'
import { createCheckoutSession } from '../../utils/payment'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { track } from '../../utils/analytics'
import useShowPage from '../../hooks/useShowPage'

const getPlanLabel = (account) => {
  if (account.status === 'trial') return 'Trial'
  if (account.status === 'canceled') return `${capitalizeFirstLetter(account.plan)} Canceled`
  return `${capitalizeFirstLetter(account.plan)} ${capitalizeFirstLetter(account.billingCycle)}`
}

const Profile = () => {
  const notification = useElaiNotification()
  const { user, refreshSession } = useStore((stores) => stores.authStore)
  const { setHeader } = useStore((stores) => stores.domStore)
  const videosStore = useStore((stores) => stores.videosStore)
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
  const [avatarId, setAvatarId] = useState()
  const [renderHistory, setRenderHistory] = useState()
  const [form] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const { name, email, account, isAdmin, canManageWorkspace } = user

  const { navigateToOnboardingVideo } = useShowPage()

  const waitForAccountUpdateStatus = async (status, attempts = 0) => {
    const { account } = await refreshSession()
    if (account.status === status) return true
    if (attempts >= 5) return false
    notification.open({
      message: `Please wait while we updating your account.${
        params.has('redirectUrl') ? " You'll be redirected as soon as it complete." : ''
      }..`,
      icon: <Spin />,
      duration: null,
      key: 'checkout-loading',
    })
    await sleep(1500)
    return waitForAccountUpdateStatus(status, attempts + 1)
  }

  const getNewAvatarId = async (attempts = 0) => {
    const avatars = await request({ method: 'get', url: 'avatars' })
    const avatar = avatars.find((avatar) => avatar.status === 4)
    if (avatar) return avatar.id
    if (attempts >= 5) {
      return false
    }
    notification.open({
      message: `Please wait while we're updating information about your purchase...`,
      icon: <Spin />,
      duration: null,
      key: 'checkout-loading',
    })
    await sleep(1500)
    return getNewAvatarId(attempts + 1)
  }

  const getNewVoiceId = async (attempts = 0) => {
    refreshSession()
    const lastVoice = user.account.voices[user.account.voices.length - 1]
    const voice = lastVoice?.status === 1 ? lastVoice : false
    if (voice) return voice._id
    if (attempts >= 5) {
      return false
    }
    notification.open({
      message: `Please wait while we're updating information about your purchase...`,
      icon: <Spin />,
      duration: null,
      key: 'checkout-loading',
    })
    await sleep(1500)
    return getNewVoiceId(attempts + 1)
  }

  const handlePaymentSuccess = async () => {
    if (params.has('checkout-success')) {
      const event = params.get('checkout-success')
      if (event === 'topup') {
        notification.success({
          message: 'Thank you! Purchased minutes were successfully added to your minutes balance.',
        })
      } else if (event === 'avatar') {
        const avatarId = await getNewAvatarId()
        if (avatarId) {
          setAvatarId(avatarId)
          setIsOpenSuccessModal(true)
          videosStore.fetchAvatars()
        } else {
          notification.error({
            message:
              'Unfortunately we were not able to confirm your purchase. Please email us at support@elai.io for further assistance.',
          })
          navigateToOnboardingVideo(true)
        }
      } else if (event === 'voice') {
        const voiceId = await getNewVoiceId()
        if (voiceId) setIsOpenSuccessModal(true)
        else {
          notification.error({
            message:
              'Unfortunately we were not able to confirm your purchase. Please email us at support@elai.io for further assistance.',
          })
          navigateToOnboardingVideo(true)
        }
      } else {
        if (await waitForAccountUpdateStatus(event)) {
          notification.success({
            message: 'Thank you for subscription! Now you can enjoy your plan and create awesome videos with Elai.',
          })
          const {
            account: { plan, billingCycle, minutesAvailable, id },
          } = await refreshSession()
          const minutes = minutesAvailable / (billingCycle === 'annual' ? 12 : 1)
          const price = plansPrices[`${plan}_${billingCycle}_${minutes}`]
          track('purchase', {
            currency: 'USD',
            value: price,
            transaction_id: id,
            subscription: plan,
            subscription_duration: billingCycle,
            plan_minutes: minutesAvailable,
            items: [
              {
                item_name: `${plan}_${billingCycle}_${minutes}`,
                price,
              },
            ],
          })
          if (params.has('redirectUrl')) {
            await sleep(2000)
            window.location.href = params.get('redirectUrl')
          }
        } else {
          notification.error({
            message:
              'Unfortunately we were not able to confirm your purchase. Please email us at support@elai.io for further assistance.',
          })
        }
        navigateToOnboardingVideo(true)
      }
      notification.destroy('checkout-loading')
    }
  }

  useEffect(() => {
    handlePaymentSuccess()
    setHeader({
      title: 'My profile',
      icon: <Icon name="personal" />,
      link: '/profile',
      extra: null,
    })
  }, [])

  const saveUserData = async (d) => {
    const res = await request({ method: 'patch', url: 'users', data: d })
    if (res === false) return
    if (res === 'email') {
      track('profile_change_email')
      notification.warning({
        message: 'Please check your inbox to confirm your new email. Otherwise you won’t be able to use Elai.',
        duration: 0,
      })
    } else {
      notification.success({ message: res.message || 'Сhanges saved successfully', duration: 10 })
    }
    await refreshSession()
    return true
  }

  const onFinish = async (d) => {
    if (d.email !== email || d.password) return confirmPassword()
    await saveUserData(d)
  }

  const createPaymentPortalSession = async () => {
    track('profile_manage_subscription')
    const url = await request({ method: 'get', url: 'payment/create-portal-session' })
    window.location.href = url
  }

  const topUpMinutes = async () => {
    track('profile_top_up_minutes')
    await createCheckoutSession({ minutes: true })
  }

  const fetchRenderHistory = async (page = 1) => {
    const history = await request({ method: 'post', url: '/users/render-history', data: { page } })
    setRenderHistory(history)
  }

  const confirmPassword = () => {
    Modal.confirm({
      title: 'Please enter your current password to save changes',
      icon: <ExclamationCircleOutlined />,
      className: 'profile-password-modal',
      content: (
        <div style={{ marginTop: 16 }}>
          <Form form={passwordForm} layout="block" validateTrigger={false}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Your password is required',
                },
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
          </Form>
        </div>
      ),
      okText: 'Ok',
      cancelText: 'Cancel',
      async onOk() {
        return passwordForm.validateFields(['password']).then(() =>
          saveUserData({
            ...form.getFieldsValue(),
            currentPassword: passwordForm.getFieldValue('password'),
          }).then((res) => (res ? Promise.resolve() : Promise.reject())),
        )
      },
      afterClose: () => passwordForm.resetFields(),
    })
  }

  const renderHistoryTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => <Link to={`/video/${record.videoId}`}>{name}</Link>,
    },
    {
      title: 'Minutes used',
      dataIndex: 'minutesUsed',
      key: 'minutesUsed',
      render: (minutesUsed) => formatSeconds(minutesUsed),
    },
    {
      title: 'Render time',
      dataIndex: 'renderTimeSec',
      key: 'renderTimeSec',
      render: (v) => formatSeconds(v),
    },
    {
      title: 'Rendered At',
      dataIndex: 'renderedAt',
      key: 'renderedAt',
      render: (d) => new Date(d).toLocaleString(),
    },
  ]

  return (
    <>
      {!canManageWorkspace && (
        <Alert
          className="header-alert"
          message={
            <div>
              <p>
                This is sub account. All limitations, minutes, billing are synced with main account. Please switch to
                primary account to manage billing.
              </p>
            </div>
          }
          type="warning"
        />
      )}
      <div className="profile-content">
        <div className="content">
          {account.status === 'paid' && account.billingCycle === 'lifetime' && (
            <Alert
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
              message={
                <>
                  <span>Your account is automatically subscribed for {getPlanLabel(account)} by </span>
                  <b>{account.source.provider} LTD</b>
                </>
              }
            />
          )}
          {account.status === 'paid' && account.planCancelAtPeriodEnd && account.planCancelsAt && (
            <Alert
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
              message={
                <>
                  Your account will be canceled automatically at{' '}
                  <b>{new Date(account.planCancelsAt).toLocaleString()}</b>. Click on "Manage subscription" to restore
                  your plan.
                </>
              }
            />
          )}
          {account.status === 'paid' && account.billingCycle?.startsWith('trial') && (
            <Alert
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
              message="Your account is in Enterprise Trial mode. It has all capabilities of your paid plan but has expiration date."
            />
          )}
          {account.allowOverlimit && (
            <Alert
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
              message="Metered Usage"
              description="You can use more minutes than your plan allows and will be invoiced additionally in the end of your billing cycle."
            />
          )}
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <h2 style={{ margin: 0, fontSize: 20 }}>My Plan</h2>
              <p className="primary-color-highlight" style={{ margin: '5px 0 0 0' }}>
                {getPlanLabel(account)}
              </p>
            </Col>
            {account.status !== 'paid' && canManageWorkspace && (
              <Button
                type="primary"
                icon={<RightOutlined />}
                className="btn-arrow"
                onClick={async () => {
                  window.location.hash = '#subscription'
                }}
              >
                Upgrade my account
              </Button>
            )}
            {canManageWorkspace && account.status === 'paid' && ['monthly', 'annual'].includes(account.billingCycle) ? (
              <>
                <Button
                  type="primary"
                  icon={<RightOutlined />}
                  className="btn-arrow"
                  onClick={createPaymentPortalSession}
                >
                  Manage subscription
                </Button>
                <p>
                  Manage subscription to enhance your plan or{' '}
                  <a style={{ color: 'inherit', textDecoration: 'underline' }} onClick={topUpMinutes}>
                    top up minutes.
                  </a>
                </p>
              </>
            ) : canManageWorkspace ? (
              <p>
                <a
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  href="https://calendly.com/elai_io/demo?utm_source=app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact sales
                </a>{' '}
                if you don’t know what plan to choose or need a custom setup.
              </p>
            ) : (
              <p>
                You are invited user. Please get in touch with your account owner regarding any billing or global
                account setting.
              </p>
            )}
          </Row>
          <div style={{ margin: '16px 0' }}>
            {account.plan === 'unlimited' ? (
              <p style={{ fontWeight: 500, margin: 0 }}>
                Seats available: <span className="primary-color-highlight">{account.maxSeats}</span>
              </p>
            ) : (
              <>
                <p style={{ fontWeight: 500, margin: 0 }}>
                  <span className="primary-color-highlight">
                    {formatSeconds(Math.round(account.minutesUsed * 60)) || '0m'}
                  </span>{' '}
                  {account.workspace && (
                    <>
                      (
                      <span className="primary-color-highlight">
                        {formatSeconds(Math.round(account.workspace.minutesUsed * 60)) || '0m'}
                      </span>{' '}
                      in this workspace){' '}
                    </>
                  )}
                  rendered from <span className="primary-color-highlight">{account.minutesAvailable}m</span> available{' '}
                  {account.workspace && (
                    <>
                      <br />
                      Total minutes used by this workspace:{' '}
                      <span className="primary-color-highlight">
                        {formatSeconds(Math.round(account.workspace.lifetimeMinutesUsed * 60)) || '0m'}
                      </span>{' '}
                    </>
                  )}
                </p>
                <Progress
                  percent={(account.minutesUsed * 100) / account.minutesAvailable}
                  strokeWidth={12}
                  showInfo={false}
                />
              </>
            )}
          </div>
          <Divider />
          <h2 style={{ margin: 0, fontSize: 20 }}>Personal Information</h2>
          {account.source?.provider === 'thinkific' && (
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col span={12}>
                <img src={thinkificLogo} />
              </Col>
              <Col span={12}>
                Your account is linked with Thinkific account <b>{account.source.thinkificSubdomain}</b>
              </Col>
            </Row>
          )}
          {isAdmin && (
            <div style={{ marginTop: 20 }}>
              <h3>Account ID: {account.id}</h3>
              <h3>User ID: {user.id}</h3>
            </div>
          )}
          <Form
            form={form}
            onFinish={onFinish}
            style={{ marginTop: 16 }}
            requiredMark={false}
            autoComplete="off"
            initialValues={{ email, name }}
            layout="vertical"
          >
            <Form.Item
              label="Name"
              name="name"
              validateTrigger="onBlur"
              rules={[{ min: 2, message: 'Name should be longer than 2 chars' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Your email is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Collapse
              expandIcon={({ isActive }) => (isActive ? <Icon name="down_arrow" /> : <Icon name="right_arrow" />)}
            >
              <Collapse.Panel header={<b>Change Password</b>}>
                <Form.Item
                  name="password"
                  label="Password"
                  validateTrigger="onBlur"
                  rules={[{ min: 8, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/ }]}
                  help={
                    <p className="form-password-hint">
                      Password should be at least 8 characters long, must contain a lowercase letter, an uppercase
                      letter, and a number
                    </p>
                  }
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if ((!value && !getFieldValue('password')) || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'))
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <Button type="primary" htmlType="submit" icon={<RightOutlined />} className="btn-save-profile btn-arrow">
              Save
            </Button>
          </Form>
          <Divider style={{ margin: '32px 0 10px' }} />
          <Collapse
            ghost
            expandIconPosition="end"
            expandIcon={({ isActive }) => (isActive ? <Icon name="down_arrow" /> : <Icon name="right_arrow" />)}
            className="render-history-collapse"
            onChange={async (openPanels) => {
              if (!renderHistory && openPanels.length) await fetchRenderHistory()
            }}
          >
            <Collapse.Panel header={<h2 style={{ margin: 0, fontSize: 20 }}>Render History</h2>}>
              <Table
                columns={renderHistoryTableColumns}
                dataSource={renderHistory?.data}
                rowKey="_id"
                loading={!renderHistory}
                className="render-history-table"
                pagination={{
                  current: renderHistory?.page,
                  pageSize: 10,
                  total: renderHistory?.total,
                  showSizeChanger: false,
                  onChange: async (page) => await fetchRenderHistory(page),
                }}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
        <SuccessPaymentModal
          avatarId={avatarId}
          isOpenSuccessModal={isOpenSuccessModal}
          setIsOpenSuccessModal={setIsOpenSuccessModal}
        />
      </div>
    </>
  )
}

export default Profile
