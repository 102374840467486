import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Dropdown, Typography } from 'antd'
import {
  removeHandler,
  copyHandler,
  pasteHandler,
  getActiveObjectLayers,
  groupObjects,
  ungroupObjects,
} from '../../../../../utils/canvas/canvas'
import {
  copyIcon,
  pasteIcon,
  deleteIcon,
  layersIcon,
  alignLeftIcon,
  rightArrowIcon,
  moveToIcon,
  groupIcon,
  ungroupIcon,
  alignMenuItemChildren,
  moveToMenuItemChildren,
  createLayerSubmenuItem,
} from '../constans'
import { osMap } from '../../../../../utils/constants'
import { os } from '../../../constants'

const { Text } = Typography

const ContextMenu = ({
  children,
  data,
  canvas,
  canvasActiveObject,
  isShiftPressed,
  setActiveObjectModifier,
  updateCanvas,
  getSlideChangesOnPasteAvatar,
}) => {
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false)
  const [contextMenuOpenKeys, _setContextMenuOpenKeys] = useState([])
  const layersSubmenuClicked = useRef(false)
  const contextMenuOpenKeysRef = useRef(null)

  const setContextMenuOpenKeys = (state) => {
    _setContextMenuOpenKeys(state)
    contextMenuOpenKeysRef.current = state
  }

  const onContextMenuOpenChange = (v) => setIsOpenContextMenu(v)

  const handleSubmenuOpen = (openKeys) => {
    if (layersSubmenuClicked.current) {
      layersSubmenuClicked.current = false
      openKeys = ['layers']
    }
    setContextMenuOpenKeys(openKeys)
  }

  const handleClickCanvasContextMenu = useCallback(
    async ({ key, keyPath }) => {
      switch (true) {
        case keyPath.includes('layers'):
          layersSubmenuClicked.current = true
          setActiveObjectModifier({ change: 'layersSelection', value: key })
          if (
            (!canvasActiveObject.group || canvasActiveObject.group.getObjects().length === 1) &&
            canvasActiveObject.id === +key &&
            isShiftPressed.current
          ) {
            setIsOpenContextMenu(false)
          }
          break
        case key === 'copy':
          {
            const { id: slideId, avatar, voice, voiceProvider } = data
            await copyHandler({ slideId, canvas, avatar, voice, voiceProvider })
          }
          break
        case key === 'paste':
          await pasteHandler(
            { slideId: data.id, canvas, speech: data.speech },
            canvas,
            updateCanvas,
            getSlideChangesOnPasteAvatar,
          )
          break
        case key === 'delete':
          removeHandler(canvas)
          canvas.renderAll()
          updateCanvas()
          break
        case key === 'group':
          groupObjects(canvas)
          updateCanvas()
          break
        case key === 'ungroup':
          ungroupObjects(canvas)
          updateCanvas()
          break
        case keyPath.includes('moveTo'):
          setActiveObjectModifier({ change: 'layering', action: key })
          break
        case keyPath.includes('align'):
          setActiveObjectModifier({ change: 'groupedAlignment', value: key })
          break
        default:
          break
      }

      if (!keyPath.includes('layers')) setIsOpenContextMenu(false)
    },
    [canvas, canvasActiveObject, data, updateCanvas],
  )

  useEffect(() => {
    if (!isOpenContextMenu && contextMenuOpenKeys.length) {
      setContextMenuOpenKeys([])
    }
  }, [isOpenContextMenu])

  const handleMenuMouseLeave = () => {
    setTimeout(() => {
      if (!contextMenuOpenKeysRef.current?.length) setIsOpenContextMenu(false)
    }, 100)
  }

  const modifierKey = os === osMap.MAC ? '⌘' : 'Ctrl+'

  const canvasContextMenuItems = useMemo(() => {
    if (!isOpenContextMenu) return []
    const pasteMenuItem = {
      key: 'paste',
      icon: pasteIcon,
      label: (
        <span className="menu-item-label">
          <span>Paste</span> <Text type="secondary">{modifierKey}V</Text>
        </span>
      ),
    }
    if (!canvasActiveObject) return [pasteMenuItem]
    const layers = getActiveObjectLayers(canvasActiveObject)
    const layersMenuItemChildren = layers.map((layer) => createLayerSubmenuItem(layer, canvasActiveObject))
    const menuItems = [
      {
        key: 'copy',
        icon: copyIcon,
        label: (
          <span className="menu-item-label">
            <span>Copy</span> <Text type="secondary">{modifierKey}C</Text>
          </span>
        ),
      },
      pasteMenuItem,
      {
        key: 'delete',
        icon: deleteIcon,
        label: (
          <span className="menu-item-label">
            <span>Delete</span> <Text type="secondary">{os === osMap.MAC ? 'Del' : 'Bcksp'}</Text>
          </span>
        ),
      },
      {
        icon: moveToIcon,
        label: 'Move to',
        key: 'moveTo',
        expandIcon: rightArrowIcon,
        children: moveToMenuItemChildren,
        popupClassName: 'canvas-context-submenu',
      },
    ]

    const groupMenuItem =
      canvasActiveObject.type === 'activeSelection'
        ? {
            key: 'group',
            icon: groupIcon,
            label: (
              <span className="menu-item-label">
                <span>Group</span> <Text type="secondary">{modifierKey}G</Text>
              </span>
            ),
          }
        : {
            key: 'ungroup',
            icon: ungroupIcon,
            label: (
              <span className="menu-item-label">
                <span>Ungroup</span>{' '}
                <Text type="secondary">
                  {modifierKey}
                  {os === osMap.MAC ? ' Shift ' : 'Shift+'}G
                </Text>
              </span>
            ),
          }

    if (canvasActiveObject._objects && canvasActiveObject.type !== 'question') menuItems.splice(3, 0, groupMenuItem)

    if (layers.length)
      menuItems.push({
        icon: layersIcon,
        label: 'Select layer',
        key: 'layers',
        expandIcon: rightArrowIcon,
        children: layersMenuItemChildren,
        popupClassName: 'canvas-layer-submenu',
      })

    if (canvasActiveObject.type === 'activeSelection')
      menuItems.push({
        icon: alignLeftIcon,
        label: 'Align elements',
        key: 'align',
        expandIcon: rightArrowIcon,
        children: alignMenuItemChildren,
        popupClassName: 'canvas-context-submenu',
      })

    return menuItems
  }, [canvasActiveObject, canvasActiveObject?.left, canvasActiveObject?.top, isOpenContextMenu])

  return (
    <Dropdown
      open={isOpenContextMenu}
      menu={{
        items: canvasContextMenuItems,
        openKeys: contextMenuOpenKeys,
        onOpenChange: handleSubmenuOpen,
        onClick: handleClickCanvasContextMenu,
      }}
      trigger={['contextMenu']}
      overlayClassName="canvas-context-menu"
      dropdownRender={(menu) => <div onMouseLeave={handleMenuMouseLeave}>{menu}</div>}
      onOpenChange={onContextMenuOpenChange}
    >
      {children}
    </Dropdown>
  )
}

export default ContextMenu
