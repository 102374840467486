import { EDITOR_SYNC_TIMEOUT_MS } from '../../routes/videoStory/constants'
import { sleep, uniqueId } from '../helpers'

const storyInternalFields = ['isTooComplex', 'initial']

const isFieldDefined = (field) => typeof field === 'string'

const resetStory = (slide) => {
  const story = (slide.story = slide.story || {})
  delete story.isTooComplex
  if (story.header?.length) story.header = ''
  if (story.subHeader?.length) story.subHeader = ''
  if (story.list?.length) story.list = ['']
  if (story.images?.length) story.images = Array(story.images.length).fill(null)
}

const isComplexStory = (story) => story?.isTooComplex === true

const isEmptyStory = (story) =>
  !isComplexStory(story) && Object.keys(story).filter((key) => !storyInternalFields.includes(key)).length === 0

const makeLayoutFromSlide = ({ canvas, story, avatar }) => {
  return { ...canvas, story, avatar, id: uniqueId() }
}

const formatInnerHtmlToText = (innerHTML) => {
  return !innerHTML || innerHTML === '<br>'
    ? ''
    : innerHTML
        .replaceAll('<br>', '\n')
        .replaceAll('&nbsp;', ' ')
        // Convert tags with empty inner content to self-closing tags
        .replace(/<(\w+)([^>]*)><\/\1>/g, '<$1$2 />')
        // return makr tags back
        .replace(/<m([^>]*)>/g, '<mark$1>')
}

// similar if: amount of objects are amost same (not more than 1 object diff) and more than 60% objects coordinates matched
const ifLayoutsSimilar = (layout1, layout2) => {
  if (Math.abs(layout1.objects.length - layout2.objects.length) > 1) return false
  let matchedObjects = 0
  if (layout1.objects && layout2.objects)
    for (const object of layout1.objects) {
      for (const object2 of layout2.objects) {
        if (object.type === object2.type && object.left === object2.left && object.top === object2.top) {
          matchedObjects++
        }
      }
    }
  // if (matchedObjects >= 2) console.log(layout1.id, layout2.id, layout1.objects.length, layout2.objects.length, matchedObjects)
  return matchedObjects >= 2 && matchedObjects / layout1.objects.length > 0.6
}

const waitForElementInDOM = async (selector) => {
  let i = 0
  while (i++ < 10) {
    await sleep(EDITOR_SYNC_TIMEOUT_MS)
    const el = document.querySelector(selector)
    if (el) return el
  }
}

export {
  storyInternalFields,
  isFieldDefined,
  resetStory,
  isComplexStory,
  isEmptyStory,
  makeLayoutFromSlide,
  formatInnerHtmlToText,
  ifLayoutsSimilar,
  waitForElementInDOM,
}
