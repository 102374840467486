import { Space, Form, Modal, Select, Tooltip } from 'antd'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { useStore } from '../../../../../../../store'

import EditableRow from './components/editableRow'
import EditableCell from './components/editableCell'

import './phonemeModal.less'
import { request } from '../../../../../../../utils/api'
import { uniqueId } from '../../../../../../../utils/helpers'
import { useElaiNotification } from '../../../../../../../hooks/useElaiNotification'

import {
  deleteIcon,
  playIcon,
  pauseIcon,
  loadingIcon,
  DEFAULT_VOICE,
  ANY_LANGUAGE_OPTION,
  DEFAULT_VOICE_PROVIDER,
} from './constants'

// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARS_PHONEME_REGEX = /(?=.*[!@#$%^&*()+{}\[\]:;<>,.?~`\\|=])|(?=.*[0-9])/g
// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARS_WORD_REGEX = /[!()_{}\[\]:;<>,.?`\\|]/g

const sortPhonemes = (phonemes) => phonemes.sort((a, b) => a.word.localeCompare(b.word))

const buildSpeechTextForPronunciation = (text, word, pronunciation, contextWordsCount = 3) => {
  const sentences = text.split(/[.!?]/)
  const lowerCaseWord = word.toLowerCase()

  const targetSentence = sentences.find((sentence) => sentence.toLowerCase().includes(lowerCaseWord))
  if (!targetSentence) return word

  const words = targetSentence
    .trim()
    .split(/\s+/)
    .map((w) => w.toLowerCase())
  const wordIndex = words.findIndex((w) => w === lowerCaseWord)

  const startIndex = Math.max(0, wordIndex - contextWordsCount)
  const endIndex = Math.min(words.length, wordIndex + contextWordsCount + 1)

  let speech = words.slice(startIndex, wordIndex).join(' ')
  speech += ' ' + [pronunciation, ...words.slice(wordIndex + 1, endIndex)].join(' ')
  return speech
}

export const usePhonemeModal = (props) => {
  const {
    data,
    voice,
    languages,
    audioCache,
    resetAudio,
    wordForDictionary,
    setWordForDictionary,
    setIsOpenPhonemeModal,
  } = props
  const notification = useElaiNotification()
  const {
    user: {
      account: { dictionary },
    },
    refreshSession,
  } = useStore((stores) => stores.authStore)

  const phonemePreviewAudio = useRef(null)

  const [form] = Form.useForm()

  const sortedDictionary = useMemo(() => sortPhonemes(dictionary), [dictionary])
  // form options for a Select
  const languageOptions = useMemo(
    () => [ANY_LANGUAGE_OPTION, ...languages.map((language) => ({ value: language.name, label: language.name }))],
    [languages],
  )

  const [editingCell, setEditingCell] = useState(false)
  const [phonemesFilter, setPhonemesFilter] = useState()
  const [phonemes, setPhonemes] = useState(sortedDictionary)
  const [filteredPhonemes, setFilteredPhonemes] = useState(null)
  const [isSavingPhonemes, setIsSavingPhonemes] = useState(false)
  const [phonemePreviewPlayer, setPhonemePreviewPlayer] = useState({ id: '', state: 'idle' })

  const handleDelete = useCallback((key) => {
    setPhonemes((phonemes) => phonemes.filter((ph) => ph.key !== key))
  }, [])

  /**
   * Select on Change event
   * when changing value update also state
   */
  const handleLanguageChange = (value, key) => {
    const updatedPhonemes = phonemes.map((phoneme) => {
      if (phoneme.key === key) {
        phoneme.language = value
        return phoneme
      }
      return phoneme
    })
    form.setFieldValue(`language_${key}`, value)
    setPhonemes(updatedPhonemes)
  }

  // adding a new phoneme
  const handleAdd = ({ word, phoneme } = {}) => {
    const rowKey = uniqueId()
    const newPhoneme = {
      key: rowKey,
      word: word || '',
      pronunciation: phoneme || '',
      language: ANY_LANGUAGE_OPTION.value,
    }
    setPhonemes([newPhoneme, ...phonemes])
    setEditingCell({ rowKey, dataIndex: 'word' })
    form.setFieldsValue({
      [`word_${rowKey}`]: word || '',
      [`pronunciation_${rowKey}`]: phoneme || '',
      [`language_${rowKey}`]: ANY_LANGUAGE_OPTION.value,
    })
  }

  const savePhonemes = async () => {
    setEditingCell(true)
    setTimeout(async () => {
      await form.validateFields()
      setEditingCell(false)
      setIsSavingPhonemes(true)
      const res = await request({ method: 'patch', url: 'accounts/dictionary', data: phonemes })
      if (res === false) return setIsSavingPhonemes(false)
      await refreshSession()
      audioCache.purgeAll()
      resetAudio()
      setIsSavingPhonemes(false)
      setIsOpenPhonemeModal(false)
    })
  }

  const resetPreviewAudio = useCallback(() => {
    phonemePreviewAudio.current = null
  }, [])

  const onCancelModal = () => {
    const phonemesData = phonemes.map(({ word, pronunciation, language }) => ({ word, pronunciation, language }))
    const dictionaryData = dictionary.map(({ word, pronunciation, language }) => ({ word, pronunciation, language }))
    if (JSON.stringify(phonemesData) !== JSON.stringify(dictionaryData))
      return Modal.confirm({
        title: `Do you want to save your changes?`,
        onOk: savePhonemes,
        onCancel: closeModal,
        okText: 'Save',
        cancelText: 'Close',
      })
    closeModal()
  }

  const closeModal = () => {
    setIsOpenPhonemeModal(false)
    if (phonemePreviewPlayer.state === 'playing') {
      setPhonemePreviewPlayer({ ...phonemePreviewPlayer, state: 'idle' })
      pausePhonemePreview()
    }
    resetPreviewAudio()
    setPhonemes(dictionary)
  }

  const createVoicePreviewAudio = useCallback(
    async ({ key, word, pronunciation, language }) => {
      let audio
      let { id: voiceId, provider } = voice

      if (!pronunciation) return notification.error({ message: 'Please input pronunciation' })
      if (SPECIAL_CHARS_PHONEME_REGEX.test(pronunciation))
        return notification.error({
          message: 'Please remove special characters and numbers from pronunciation',
        })

      setPhonemePreviewPlayer({ id: key, state: 'loading' })

      if (!voiceId || !provider) {
        voiceId = DEFAULT_VOICE
        provider = DEFAULT_VOICE_PROVIDER
      }

      if (provider === 'elevenlabs' && data.speech.includes(word)) {
        pronunciation = buildSpeechTextForPronunciation(data.speech, word, pronunciation)
      }

      const res = await request({
        method: 'patch',
        url: `/voices`,
        data: { text: pronunciation, voice: voiceId, provider },
      })
      if (!res) return setPhonemePreviewPlayer((player) => ({ ...player, state: 'idle' }))
      audio = new Audio(`data:audio/x-wav;base64, ${res.data}`)

      audio.onended = () => setPhonemePreviewPlayer({ id: key, state: 'idle' })
      audio.onloadeddata = () => setPhonemePreviewPlayer({ id: key, state: 'playing' })
      phonemePreviewAudio.current = audio
      audio.play()
    },
    [voice, data.speech, phonemePreviewAudio.current],
  )

  const playPhonemePreview = useCallback(
    (phoneme) => {
      if (phonemePreviewAudio.current) {
        if (phoneme.key === phonemePreviewPlayer.id) {
          setPhonemePreviewPlayer({ id: phoneme.key, state: 'playing' })
          phonemePreviewAudio.current.play()
          return
        } else {
          phonemePreviewAudio.current.pause()
          createVoicePreviewAudio(phoneme)
        }
      } else {
        createVoicePreviewAudio(phoneme)
      }
    },
    [phonemePreviewAudio.current, phonemePreviewPlayer.id, createVoicePreviewAudio],
  )

  const pausePhonemePreview = () => {
    if (phonemePreviewAudio.current) phonemePreviewAudio.current.pause()
    setPhonemePreviewPlayer({ ...phonemePreviewPlayer, state: 'idle' })
  }

  const onSearchPhoneme = (value) => {
    setPhonemesFilter(value)
  }

  useEffect(() => {
    // reset phonemes on dictionary change after refreshing a session
    setPhonemes(sortedDictionary)
  }, [sortedDictionary])

  useEffect(() => {
    if (wordForDictionary) {
      handleAdd(wordForDictionary)
      setWordForDictionary(null)
    }
  }, [wordForDictionary])

  useEffect(() => {
    if (phonemesFilter) {
      const filteredPhonemes = phonemes.filter((phoneme) =>
        phoneme.word.toLowerCase().includes(phonemesFilter.toLowerCase()),
      )
      setFilteredPhonemes(filteredPhonemes)
    } else {
      setFilteredPhonemes(null)
    }
  }, [phonemes, phonemesFilter])

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const defaultColumns = useMemo(
    () => [
      {
        title: 'Word',
        dataIndex: 'word',
        width: '30%',
        editable: true,
      },
      {
        title: 'Pronunciation',
        dataIndex: 'pronunciation',
        width: '30%',
        editable: true,
      },
      {
        title: 'Language',
        dataIndex: 'language',
        width: '30%',
        render: (select, record) => {
          return (
            <Select
              showSearch
              className="phone-language-selector"
              options={languageOptions}
              defaultValue={record?.language || ANY_LANGUAGE_OPTION.value}
              onChange={(value) => handleLanguageChange(value, record.key)}
            />
          )
        },
      },
      {
        dataIndex: 'operation',
        align: 'center',
        render: (_, record) =>
          phonemes.length >= 1 ? (
            <Space className="table-actions-wrapper" align="center">
              {phonemePreviewPlayer.state !== 'idle' && phonemePreviewPlayer.id === record.key ? (
                <>
                  {phonemePreviewPlayer.state === 'playing' ? (
                    <span onClick={pausePhonemePreview}>{pauseIcon}</span>
                  ) : (
                    loadingIcon
                  )}
                </>
              ) : (
                <Tooltip
                  title={
                    record?.language && record.language !== 'Any' && record.language !== data.language
                      ? `Please change your slide to ${record.language} to enable preview for this Phoneme`
                      : null
                  }
                >
                  <span
                    className={
                      record?.language && record.language !== 'Any' && record.language !== data.language
                        ? 'phoneme-preview-disabled'
                        : null
                    }
                    onClick={() => {
                      if (record?.language && record.language !== 'Any' && record.language !== data.language) {
                        return
                      }
                      playPhonemePreview(record)
                    }}
                  >
                    {playIcon}
                  </span>
                </Tooltip>
              )}
              <span onClick={() => handleDelete(record.key)}>{deleteIcon}</span>
            </Space>
          ) : null,
      },
    ],
    [
      phonemes,
      phonemePreviewPlayer.state,
      phonemePreviewPlayer.id,
      handleDelete,
      playPhonemePreview,
      pausePhonemePreview,
    ],
  )

  const columns = useMemo(
    () =>
      defaultColumns.map((col) => {
        if (!col.editable) {
          return col
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            form,
            phonemes,
            setPhonemes,
            editingCell,
            setEditingCell,
            resetPreviewAudio,
          }),
        }
      }),
    [defaultColumns, editingCell, phonemes],
  )

  return {
    phonemes,
    components,
    columns,
    form,
    isSavingPhonemes,
    filteredPhonemes,
    savePhonemes,
    handleAdd,
    onCancelModal,
    onSearchPhoneme,
  }
}
