/* eslint-disable jsx-a11y/alt-text */
import { Button, Modal, Row, Tabs, Tooltip } from 'antd'

import { Music } from './music'
import { Avatars } from './avatars'
import Templates from './templates'
import { Animations } from './animations'
import Elements from './elements/index'
import Icon from '../../../components/Icon'
import { ScreenRecorder } from './screenRecorder'
import { TextControls } from './elements/textControls'
import FileUploader from './fileUploader/fileUploader'
import { Comments } from '../../../components/Comments/comments'
import { CommentsBadge } from '../../../components/Comments/commentsBadge'
import ShortcutsPopover from './components/shortcutPopover/shortcutsPopover'
import Interactivity from './interactivity/interactivity'
import { useSidebarState } from './useSidebarState'
import './sidebar.less'

const iconPlus = <Icon name="plus" />
const buttonStyle = { color: 'gray', fill: 'gray' }
const STYLE = { fullHeight: { height: '100%' }, paddingBottom32: { paddingBottom: 32 } }

export const Sidebar = (props) => {
  const {
    data,
    video,
    playVideo,
    activeTab,
    stopVideo,
    activeSlide,
    updateSlide,
    updateVideo,
    player,
    setActiveTab,
    isOpenSidebar,
    setIsOpenSidebar,
    updateActiveSlide,
    canvasActiveObject,
    addSlideFromTemplate,
    activeObjectModifier,
    setActiveObjectModifier,
    isApplyingTemplateSlide,
  } = props

  const {
    handleOk,
    backArrow,
    modalData,
    onTabClick,
    setBackArrow,
    handleCancel,
    displaySidebar,
    activeObjectType,
    handleChooseFile,
    handleBackArrowClick,
    isShouldUpdateUploads,
    user,
    setShouldUpdateUploads,
    handleUploadRemoved,
    isOpenShortcutsPopover,
    shortcutsPopoverContentRef,
    btnOpenShortcutsPopoverRef,
    onCloseShortcutsPopover,
    toggleShortcutsPopover,
  } = useSidebarState({
    canvasActiveObject,
    isOpenSidebar,
    setIsOpenSidebar,
    setActiveTab,
    activeTab,
    setActiveObjectModifier,
    player,
    video,
    updateVideo,
  })

  const arrowIcon = <Icon name={isOpenSidebar ? 'right_arrow' : 'left_arrow'} />

  const tabsDisabled = player.activePreview || player.status !== 'idle' || isApplyingTemplateSlide

  const itemList = [
    {
      key: 'presenter',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="user" />
          <span className="tab-text">Avatar</span>
        </div>
      ),
      children: (
        <Avatars
          data={data}
          video={video}
          updateSlide={updateSlide}
          updateVideo={updateVideo}
          canvasActiveObject={canvasActiveObject}
          activeObjectModifier={activeObjectModifier}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      ),
    },
    {
      key: 'text',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="text" />
          <span className="tab-text">Text</span>
        </div>
      ),
      children: (
        <TextControls
          data={data}
          video={video}
          activeSlide={activeSlide}
          updateVideo={updateVideo}
          activeObjectType={activeObjectType}
          canvasActiveObject={canvasActiveObject}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      ),
    },
    {
      key: 'elements',
      disabled: player.activePreview,
      label: (
        <div className="main-menu-item">
          <Icon name="elements" className="tab-icon" />
          <span className="tab-text">Elements</span>
        </div>
      ),
      children: (
        <Elements
          data={data}
          video={video}
          activeSlide={activeSlide}
          backArrow={backArrow}
          setBackArrow={setBackArrow}
          activeObjectType={activeObjectType}
          canvasActiveObject={canvasActiveObject}
          handleBackArrowClick={handleBackArrowClick}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      ),
    },
    {
      key: 'interactivity',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="bolt" className="tab-icon" />
          <span className="tab-text">Interactivity</span>
        </div>
      ),
      children: (
        <Interactivity
          data={data}
          video={video}
          activeSlide={activeSlide}
          canvasActiveObject={canvasActiveObject}
          setActiveObjectModifier={setActiveObjectModifier}
          updateSlide={updateSlide}
          handleBackArrowClick={handleBackArrowClick}
        />
      ),
    },
    {
      key: 'uploads',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="upload" className="tab-icon" />
          <span className="tab-text">Uploads</span>
        </div>
      ),
      children: (
        <FileUploader
          visible={true}
          isVideoAllowed={true}
          setShouldUpdateUploads={setShouldUpdateUploads}
          isShouldUpdateUploads={isShouldUpdateUploads}
          onChooseFile={handleChooseFile}
          handleUploadRemoved={handleUploadRemoved}
        />
      ),
    },
    {
      key: 'record',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="record" className="tab-icon" />
          <span className="tab-text">Record</span>
        </div>
      ),
      children: (
        <ScreenRecorder
          setShouldUpdateUploads={setShouldUpdateUploads}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      ),
    },
    {
      key: 'animate',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="animation" className="tab-icon" />
          <span className="tab-text">Animation</span>
        </div>
      ),
      children: (
        <div className="tab-content">
          <Animations
            data={data}
            updateSlide={(animationId, type) =>
              updateSlide({ [type === 'Enter' ? 'animation' : 'exitAnimation']: animationId })
            }
          />
        </div>
      ),
    },
    {
      key: 'music',
      label: (
        <div className="main-menu-item">
          <Icon name="music" />
          <span className="tab-text">Music</span>
        </div>
      ),
      children: (
        <Music
          video={video}
          playVideo={playVideo}
          stopVideo={stopVideo}
          updateVideo={updateVideo}
          activeSlide={activeSlide}
          player={player}
        />
      ),
    },
    {
      key: 'templates',
      disabled: tabsDisabled,
      label: (
        <div className="main-menu-item">
          <Icon name="image" />
          <span className="tab-text">Templates</span>
        </div>
      ),
      children: (
        <Templates
          isNewSlide={data.newlyAdded}
          videoFormat={video.data?.format}
          slidesCount={video.slides.length}
          addSlideFromTemplate={addSlideFromTemplate}
        />
      ),
    },
    {
      key: 'comments',
      disabled: player.activePreview || !['custom', 'unlimited'].includes(user.account.plan),
      label: (
        <Tooltip
          title="Comments are available only in Enterprise plans"
          overlayStyle={{
            visibility: ['custom', 'unlimited'].includes(user.account.plan) ? 'hidden' : 'visible',
          }}
          placement="topRight"
        >
          <div className="main-menu-item">
            <CommentsBadge comments={video.comments} userId={user.id}>
              <Icon name="comment" />
            </CommentsBadge>
            <span className="tab-text">Comments</span>
          </div>
        </Tooltip>
      ),
      children: (
        <div className="tab-content" style={{ ...STYLE.fullHeight, ...STYLE.paddingBottom32 }}>
          <Comments
            video={video}
            activeSlide={data.id}
            isEnabledSwitchingSlide={true}
            defaultAnchorToSlides={true}
            updateVideo={updateVideo}
            updateActiveSlide={updateActiveSlide}
          />
        </div>
      ),
    },
  ]

  const modalFooter = (
    <Row justify="space-between">
      <Button type="primary" icon={iconPlus} onClick={handleOk}>
        Fit
      </Button>
      <Button ghost type="primary" onClick={handleCancel} style={buttonStyle}>
        Cancel
      </Button>
    </Row>
  )
  return (
    <>
      <div className={`sidebar-wrapper ${isOpenSidebar ? 'open' : ''}`}>
        <Button icon={arrowIcon} className="btn-collapse-sidebar" onClick={displaySidebar} />
        <Tabs
          activeKey={activeTab}
          onTabClick={onTabClick}
          className="sidebar"
          tabPosition="right"
          items={itemList}
          tabBarExtraContent={
            <ShortcutsPopover
              shortcutsPopoverContentRef={shortcutsPopoverContentRef}
              isOpenShortcutsPopover={isOpenShortcutsPopover}
              onCloseShortcutsPopover={onCloseShortcutsPopover}
            >
              <Button
                ref={btnOpenShortcutsPopoverRef}
                type="text"
                className="btn-shortcuts"
                onClick={toggleShortcutsPopover}
              >
                <Icon name="keyboard" />
              </Button>
            </ShortcutsPopover>
          }
        />
      </div>
      <Modal
        title="Fit Screen Recording to Audio"
        open={modalData.isOpen}
        onCancel={handleCancel}
        footer={modalFooter}
        width={500}
      >
        <p>Do you want this video to fit your speech?</p>
      </Modal>
    </>
  )
}
