import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Layout,
  Alert,
  Form,
  Input,
  Upload,
  Button,
  Timeline,
  Progress,
  Checkbox,
  Spin,
  Typography,
  Row,
  Col,
  Steps,
  Radio,
  Collapse,
} from 'antd'
import {
  InboxOutlined,
  RightOutlined,
  ClockCircleOutlined,
  LinkOutlined,
  UserOutlined,
  CheckCircleFilled,
  AppleOutlined,
  AndroidOutlined,
} from '@ant-design/icons'
import Icon from '../../components/Icon'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import AgreementCheckbox from './components/agreementCheckbox'
import Voice from './voice'
import steps_shape from '../../assets/images/steps-shape.png'
import './index.less'
import '../auth/poll/poll.less'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const { Dragger } = Upload
const { Step } = Steps
const { Panel } = Collapse

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const ManageAvatar = () => {
  const notification = useElaiNotification()
  const [form] = Form.useForm()
  const { id } = useParams()
  const authStore = useStore((stores) => stores.authStore)
  const { setHeader } = useStore((stores) => stores.domStore)
  const [fileList, setFileList] = useState([])
  const [avatar, setAvatar] = useState()
  const [step, setStep] = useState(0)
  const [device, setDevice] = useState('ios')
  const [loaderAvatar, setLoaderAvatar] = useState(false)

  const fetchAvatar = async () => {
    setLoaderAvatar(true)
    const avatar = await request({ method: 'get', url: `/avatars/${id}` })
    setAvatar(avatar)
    setLoaderAvatar(false)
  }

  useEffect(() => {
    setHeader({
      title: 'Home',
      icon: <Icon name="house" />,
      link: '/',
      extra: null,
    })
    const fetchAvatar = async () => {
      await authStore.refreshSession()
      await fetchAvatar()
    }
    fetchAvatar()
  }, [])

  const onFinish = async (data) => {
    setLoaderAvatar(true)
    data.images = []
    data.images = await Promise.all(
      fileList.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve({ name: file.name, data: reader.result })
            reader.onerror = reject
          }),
      ),
    )
    delete data.files
    const res = await request({ method: 'patch', url: `/avatars/${id}`, data })
    if (res === false) return setLoaderAvatar(false)
    await fetchAvatar()
    notification.success({
      duration: 10,
      message: `Thank you for providing the necessary data to create your avatar. We will send you an email as soon as it's ready.`,
    })
  }

  const beforeUpload = (file, fileList) => {
    if (file.size / 1024 / 1024 > 20) {
      notification.error({
        message: 'File must be smaller than 20MB',
      })
      return false
    }
    setFileList(fileList)
    return false
  }

  const uploadProps = {
    name: 'files',
    accept: 'image/jpeg, image/png',
    multiple: true,
    fileList,
    beforeUpload,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
  }

  const avatarsData = {
    selfie: {
      header: 'selfie avatar',
      steps: [
        {
          title: 'Choose your device',
          subtitle: 'Here you can see some instructions on how to prepare for your shooting',
          accordion: [
            {
              ios: {
                title: 'Select 4K at 30FPS',
                content: (
                  <ol>
                    <li>Select "Video" in your camera app</li>
                    <li>In top right corner find resolution and FPS (HD, 30)</li>
                    <li>Tap on your current resolution to choose the highest one, ideally 4K</li>
                    <li>Tap on FPS to choose 30</li>
                  </ol>
                ),
              },
              android: {
                title: 'Select UHD 4k',
                content: (
                  <ol>
                    <li>Launch the camera application</li>
                    <li>Tap the menu icon in the top left corner</li>
                    <li>Select Settings</li>
                    <li>Find the Video section and tap on Back camera video resolution</li>
                    <li>Select highest resolution possible with 30FPS (ideally UHD 4K)</li>
                  </ol>
                ),
              },
            },
            {
              title: 'Proper lighting',
              content:
                'Your avatar will look just like you in the video so make sure you have proper lighting. Use natural light from windows. Professional lighting equipment or a movie lamp set would be a winning move.',
            },
            {
              title: 'Makeup & Outfit',
              content:
                'Check your makeup and pick an appropriate outfit. We will not be able to change the avatar’s look later.',
            },
            {
              title: 'Neutral background',
              content:
                'With Elai selfie avatar you can keep your background or have it removed, so choose a proper neutral background that you want to keep on your videos.',
            },
          ],
          video: 'https://www.youtube.com/embed/fxc69FAUkBk',
        },
        {
          title: 'Shooting stage',
          subtitle: 'Follow the requirements below to make a perfect footage',
          accordion: [
            {
              title: '2-3 minutes long speech',
              content:
                'Your recording should be 2-3 minutes long. Check your camera while recording to make sure you have at least 2 minutes of quality footage.',
            },
            {
              title: 'Your movements should be natural',
              content: `You can use a few gestures and facial movements, but don't be too expressive. Expressive head and body movements won't match the speech and make it look less natural.`,
            },
            {
              title: 'Always look at your camera',
              content:
                'Always look at your camera and not at your screen to maintain eye contact with your audience and keep a persuasive vibe and confident look.',
            },
            {
              title: 'Your language choice do not matter',
              content:
                'Your speech topic and the language choice do not matter. Just tell about anything to feel comfortable.',
            },
            {
              title: 'Make small 1-2 seconds pauses',
              content:
                'Do them when you finish with idea so they feel natural. 4-5 pauses per speech would be enough. Pauses should be natural and with the mouth closed.',
            },
            {
              title: 'Additional 1-2 minutes (optional)',
              content:
                'To make the quality even better, you can also record additional 1-2 minutes of more expressive and amplitude movements of your mouth, so our AI can learn how your mouth and your teeth look and move.',
            },
          ],
          video: 'https://www.youtube.com/embed/rBX_z9WU5dY',
        },
        {
          title: 'Upload your footage',
          subtitle:
            'The last step is to upload your video to cloud and share your files with avatars@elai.io. For isntance, here is how to do it with Google Drive.',
          list: [
            <>
              <span>Log in</span> to your Google Drive account.
            </>,
            <>
              <span>Go to “My Drive”</span> and create a “New Folder”.
            </>,
            <>
              Name it <span>“Selfie Avatar”</span>.
            </>,
            <>
              <span>Upload your file(s)</span> to your folder.
            </>,
            <>
              Click on your folder and choose <span>“Share”</span>.
            </>,
            <>
              Share your footage with <span>avatars@elai.io</span>.
            </>,
            <>
              <span>Copy the link to your folder</span> and paste it here.
            </>,
          ],
          form: (
            <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
              <Form.Item
                name="name"
                label="Avatar name"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: 'Avatar name is required',
                  },
                ]}
              >
                <Input placeholder="Name" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="url"
                label="Link to your footage"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    type: 'url',
                    message: 'Link is required',
                  },
                ]}
              >
                <Input placeholder="Please make sure it's shared with avatars@elai.io" prefix={<LinkOutlined />} />
              </Form.Item>
              <AgreementCheckbox />
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<RightOutlined />} className="btn-arrow" size="large">
                  Send
                </Button>
              </Form.Item>
            </Form>
          ),
        },
      ],
    },
    studio: {
      header: 'studio avatar',
      title: 'Upload your footage',
      subtitle: (
        <>
          The quality of your avatar will 100% depend on the footage being made in accordance with{' '}
          <a target="_blank" href="https://elai.io/custom-avatar" rel="noreferrer">
            instructions
          </a>
        </>
      ),
      list: [
        <>
          <span>The green background</span> is very important.
        </>,
        <>
          <span>Good lighting</span> ensures the high quality of the footage.
        </>,
        <>
          The camera should film at least in <span>Ultra HD (4K)</span>.
        </>,
        <>
          Make small <span>1-2 seconds pauses</span>.
        </>,
        <>
          <span>Your movements</span> should be natural and calm.
        </>,
        <>
          Share your footage with <span>avatars@elai.io</span>.
        </>,
      ],
      readMore: 'https://drive.google.com/file/d/1W9rA_KDGVZYywUCbdNVn0JHU6hVKb2kV',
      form: (
        <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Avatar name"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: 'Avatar name is required',
              },
            ]}
          >
            <Input placeholder="Name" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="url"
            label="Link to your footage"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                type: 'url',
                message: 'Link is required',
              },
            ]}
          >
            <Input placeholder="Please make sure it's shared with avatars@elai.io" prefix={<LinkOutlined />} />
          </Form.Item>
          <Form.Item name="colorCorrection" valuePropName="checked" style={{ marginTop: 20 }}>
            <Checkbox>Do you want us to make a Color Correction of your footage?</Checkbox>
          </Form.Item>
          <AgreementCheckbox />
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<RightOutlined />} className="btn-arrow" size="large">
              Send
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    mascot: {
      header: 'mascot avatar',
      title: 'Upload mascot photos',
      subtitle:
        'Upload an image of your mascot and our team will provide you with 4 different animated bodies that will be converted into fictional Avatars as well.',
      list: [
        <>Find some sample pictures/photos of your mascot.</>,
        <>
          <span>Upload these pictures here.</span> The more samples we have - the better.
        </>,
        <>
          Wait for the <span>confirmation letter</span> from the Elai team with samples and further steps.
        </>,
        <>
          Get 4 different <span>animated bodies</span>
        </>,
        <>
          After all of the looks are ready, <span>confirm or request changes</span>.
        </>,
        <>
          <span>Enjoy the cartoon mascot</span> in your Elai builder!
        </>,
      ],
      form: (
        <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Mascot name"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: 'Mascot name is required',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Mascot photos"
            name="files"
            validateTrigger="onSubmit"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                validator: (_, value) =>
                  !value[0] || value[0].error ? Promise.reject(new Error()) : Promise.resolve(),
                message: 'Please add pictures of the mascot',
              },
            ]}
          >
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag photos to this area</p>
            </Dragger>
          </Form.Item>
          <AgreementCheckbox />
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<RightOutlined />} className="btn-arrow" size="large">
              Send
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  }

  return (
    <>
      <Layout.Content>
        <div className="container">
          {loaderAvatar || !avatar ? (
            <Spin size="large" style={{ width: '100%' }} />
          ) : (
            <>
              <div className="avatar-card">
                <div className="title">
                  <h2>
                    {avatar.status === 4
                      ? `Create your ${avatarsData[avatar.type].header}`
                      : avatar.status === 5
                      ? `${avatar.name} is being prepared`
                      : `${avatar.name} status`}
                  </h2>
                </div>
                <div className="main">
                  {avatar.status === 4 ? (
                    <>
                      <h3>
                        {avatarsData[avatar.type].steps
                          ? avatarsData[avatar.type].steps[step].title
                          : avatarsData[avatar.type].title}
                      </h3>
                      <Typography.Text type="secondary">
                        {avatarsData[avatar.type].steps
                          ? avatarsData[avatar.type].steps[step].subtitle
                          : avatarsData[avatar.type].subtitle}
                      </Typography.Text>
                      {avatar.type === 'selfie' && (
                        <>
                          <div className="steps-wrapper">
                            <Steps
                              current={step}
                              responsive={false}
                              progressDot={(iconDot, { status }) => {
                                if (status === 'wait') return <span className="wait-icon"></span>
                                if (status === 'process')
                                  return (
                                    <span className="progress-icon">
                                      <span className="progress-icon-point"></span>
                                    </span>
                                  )
                                if (status === 'finish') return <CheckCircleFilled />
                              }}
                            >
                              {[0, 1, 2].map((step) => (
                                <Step key={step} onClick={() => setStep(step)} />
                              ))}
                            </Steps>
                          </div>
                          {step === 0 && (
                            <div className="btns-wrapper">
                              <Radio.Group
                                className="format-radio-btns"
                                value={device}
                                onChange={(e) => setDevice(e.target.value)}
                              >
                                <Radio.Button
                                  value="ios"
                                  className={`format-radio-btn ${device === 'ios' ? 'active' : ''}`}
                                >
                                  <AppleOutlined />
                                  <span>IOS</span>
                                </Radio.Button>
                                <Radio.Button
                                  value="android"
                                  className={`format-radio-btn ${device === 'android' ? 'active' : ''}`}
                                >
                                  <AndroidOutlined />
                                  <span>Android</span>
                                </Radio.Button>
                              </Radio.Group>
                            </div>
                          )}
                        </>
                      )}
                      <Row gutter={[30, 30]}>
                        <Col md={avatar.type === 'selfie' && step !== 2 ? 10 : 12} sm={24} xs={24}>
                          {avatarsData[avatar.type].steps?.[step]?.accordion ? (
                            <>
                              <Collapse accordion expandIconPosition="end" className="card-collapse">
                                {avatarsData[avatar.type].steps[step].accordion.map((panel, index) => (
                                  <Panel header={panel.title || panel[device].title} key={index}>
                                    <span>{panel.content || panel[device].content}</span>
                                  </Panel>
                                ))}
                              </Collapse>
                              <a
                                target="_blank"
                                href="https://drive.google.com/file/d/1W9rA_KDGVZYywUCbdNVn0JHU6hVKb2kV"
                                rel="noreferrer"
                                style={{ display: 'block', marginTop: 20, fontWeight: 'bold' }}
                              >
                                Read more
                              </a>
                            </>
                          ) : (
                            <>
                              <ul className="instruction-list">
                                {(avatarsData[avatar.type].steps
                                  ? avatarsData[avatar.type].steps[step].list
                                  : avatarsData[avatar.type].list
                                ).map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))}
                                {avatarsData[avatar.type].readMore && (
                                  <a target="_blank" href={avatarsData[avatar.type].readMore} rel="noreferrer">
                                    Read more
                                  </a>
                                )}
                              </ul>
                            </>
                          )}
                        </Col>
                        <Col md={avatar.type === 'selfie' && step !== 2 ? 14 : 12} sm={24} xs={24}>
                          {avatarsData[avatar.type].steps?.[step]?.video ? (
                            <div className="video-container">
                              <iframe
                                className="youtube-video"
                                src={avatarsData[avatar.type].steps[step].video}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          ) : avatarsData[avatar.type].steps ? (
                            avatarsData[avatar.type].steps[step].form
                          ) : (
                            avatarsData[avatar.type].form
                          )}
                        </Col>
                      </Row>
                      {avatar.type === 'selfie' && step < 2 && (
                        <Button
                          type="primary"
                          icon={<RightOutlined />}
                          className="btn-login btn-arrow"
                          size="large"
                          onClick={() => setStep(step + 1 > 2 ? step : step + 1)}
                        >
                          Continue
                        </Button>
                      )}
                    </>
                  ) : (
                    <Row gutter={[60, 60]}>
                      <Col md={12} sm={20} xs={20} push={2}>
                        {avatar?.status === 5 && (
                          <>
                            <h2>Processing...</h2>
                            <Progress
                              percent={100}
                              status="active"
                              showInfo={false}
                              className="progress"
                              strokeColor={{
                                '0%': '#4868FF',
                                '100%': '#87d068',
                              }}
                            />
                          </>
                        )}
                        <Alert
                          message={
                            avatar?.status === 3
                              ? 'Avatar is not paid. Please pay for the avatar before proceeding.'
                              : avatar?.status === 5
                              ? "Your avatar is being prepared. Our AI would need from 2 to 5 business days to create your avatar. You will receive an email as soon as it's ready."
                              : avatar?.status === 2
                              ? 'Your avatar is ready. You can find it in the sidebar when editing videos.'
                              : 'Your avatar is unavailable. Please contact support.'
                          }
                          type="info"
                          showIcon
                        />
                      </Col>
                      <Col md={8} sm={24} xs={24} push={4}>
                        {avatar?.status === 5 && (
                          <Timeline>
                            <Timeline.Item color="green">Payment</Timeline.Item>
                            <Timeline.Item color="green">Creating and sending footage</Timeline.Item>
                            <Timeline.Item
                              dot={
                                <span className="progress-icon">
                                  <ClockCircleOutlined />
                                </span>
                              }
                            >
                              AI-models are preparing your avatar
                            </Timeline.Item>
                            <Timeline.Item
                              dot={
                                <span className="progress-icon">
                                  <span className="progress-icon-point"></span>
                                </span>
                              }
                            >
                              Your avatar is ready
                            </Timeline.Item>
                          </Timeline>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
                <img src={steps_shape} className="steps-shape-img" />
              </div>
            </>
          )}
        </div>
      </Layout.Content>
      <Voice />
    </>
  )
}

export default ManageAvatar
