import { textTypes } from '../canvas/canvas'

export const applyFabricMigration = (video) => {
  for (const slide of video.slides) {
    if (!slide.canvas?.objects?.length) continue
    for (const obj of slide.canvas.objects) {
      applyTextMigration(obj)
    }
  }
}

export const applyTextMigration = (obj) => {
  if (textTypes.includes(obj.type) && !obj.styles) obj.styles = {}
}
