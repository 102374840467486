import { Input, Button, Progress } from 'antd'

import Icon from '../../../Icon'
import CreateVideoFromText from '../createVideoFromText'
import { getContentFromClipboard } from '../../../../utils/videoStory/htmlParser'

import { OPTIONS } from './constants'
import { useEffect, useRef } from 'react'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { useNavigate, useParams } from 'react-router-dom'
import { requestCreateStory } from '../../../../utils/videoCreation/videoCreation'
import { defaultTemplateId } from '../../../../utils/constants'

const marginBottom10 = { marginBottom: '10px' }

export const OptionComponent = (props) => {
  const {
    currentOption,
    gptValue,
    setGPTValue,
    htmlToVideo,
    isVideoCreating,
    setHtmlToVideo,
    setIsGenerationProgressVisible,
    generationProgress,
    pastedContent,
    setPastedContent,
    handleAfterCreateVideo,
    setIsVideoCreating,
  } = props

  const navigate = useNavigate()
  const { folderId } = useParams()

  const inputRef = useRef(null)
  const notification = useElaiNotification()

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus({ cursor: 'end' }), 100)
  })

  const handleSubmit = async () => {
    setIsVideoCreating(true)
    const from = currentOption === OPTIONS.url ? htmlToVideo : undefined
    const videoId = await requestCreateStory({ type: currentOption, from, folderId })
    sessionStorage.setItem('gptValue', currentOption === OPTIONS.topic ? gptValue : '')
    sessionStorage.setItem(
      'pasteValue',
      pastedContent.content && currentOption !== OPTIONS.scratch ? pastedContent.content : '',
    )
    const state = currentOption === OPTIONS.url ? null : { templateId: defaultTemplateId }
    navigate(`/story/${videoId}`, { state })
    handleAfterCreateVideo()
  }

  const handleSubmitGpt = (e) => {
    e.stopPropagation()
    if (!gptValue)
      return notification.error({
        message: 'Please input topic of your video',
      })
    handleSubmit()
  }

  const handleSubmitScratch = () => {
    setPastedContent({ content: '', type: null })
    handleSubmit()
  }

  const onGPTValueChange = (event) => {
    setGPTValue(event.target.value)
  }

  const handleContentToParsePaste = (e) => {
    const { content, type } = getContentFromClipboard(e)
    if (!content) return
    setPastedContent({ content, type })
  }

  const handleContentToParseKeyDown = (e) => {
    if (e.key === 'Backspace' || (e.key === 'Escape' && pastedContent.content.length)) {
      e.stopPropagation()
      setPastedContent({ content: '', type: null })
    }
  }

  return (
    <div className="option-container">
      {currentOption === OPTIONS.topic && (
        <>
          <ul style={{ marginBottom: '20px', marginLeft: '4px' }}>
            <li>Type a video topic, and the script will be generated with the help of AI</li>
            <li>Edit the script as needed and convert it into video slides</li>
          </ul>
          <div className="story-input-container">
            <Input
              className="story-input"
              placeholder="Soft skills training"
              value={gptValue}
              onChange={onGPTValueChange}
            />
            <Button type="primary" className="generate-button" onClick={handleSubmitGpt} loading={isVideoCreating}>
              <Icon name="robot" /> Generate
            </Button>
          </div>
        </>
      )}
      {currentOption === OPTIONS.scratch && (
        <>
          <ul className="mixed-ul" style={{ marginLeft: '4px' }}>
            <li>Begin by selecting the structure for each slide </li>
            <p>Select various designs such as titles, bullet points, images to match your content</p>
            <li>Compose your script slide by slide</li>
            <p>Develop your script one slide at a time for a well-structured presentation</p>
            <li>Take advantage of the AI assistance</li>
            <p>Use AI to enhance and improve your content to make engaging, clear and compelling</p>
          </ul>
          <Button type="primary" onClick={handleSubmitScratch} loading={isVideoCreating}>
            Get started!
          </Button>
        </>
      )}
      {currentOption === OPTIONS.url && (
        <div style={marginBottom10}>
          {isVideoCreating && <Progress percent={generationProgress} status="active" strokeColor="#4868FF" />}
          <CreateVideoFromText
            htmlToVideo={htmlToVideo}
            setHtmlToVideo={setHtmlToVideo}
            setIsGenerationProgressVisible={setIsGenerationProgressVisible}
            handleSubmit={handleSubmit}
            isVideoCreating={isVideoCreating}
          />
        </div>
      )}
      {currentOption === OPTIONS.text && (
        <>
          <ul style={{ marginLeft: '4px' }}>
            <li>Copy text from Google Docs, Word document or any other resources</li>
            <li>Paste it below and our system will automatically parse it to slides</li>
          </ul>
          <Input.TextArea
            ref={inputRef}
            rows={6}
            value={pastedContent.content}
            onPaste={handleContentToParsePaste}
            onKeyDown={handleContentToParseKeyDown}
            placeholder="Paste your text here"
            style={{ marginBottom: '10px' }}
          />
          <Button
            type="primary"
            className="btn-arrow"
            disabled={pastedContent.content.length === 0}
            onClick={handleSubmit}
            loading={isVideoCreating}
          >
            Generate slides
          </Button>
        </>
      )}
    </div>
  )
}
