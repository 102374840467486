import { Link } from 'react-router-dom'
import { Button, Spin, Layout, Result } from 'antd'
import { CoffeeOutlined } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import './videoStory.less'
import { TemplatesModal } from './components/templatesModal'
import SlideItem from './components/slideItem/slideItem'
import { VideoHeader } from './components/videoHeader'
import { Sidebar } from './components/sidebar'
import { TopicInput } from './components/topicInput'
import { OutlineModal } from './components/outlineModal'
import { SlideCountModal } from './components/slideCountModal'
import { ImageModal } from './components/imageModal'
import { ValidationModal } from './components/validationModal'
import { useVideoStory } from './useVideoStory'

const VideoStory = () => {
  const {
    video,
    videoSavingStatus,
    isVideoGenerating,
    isBlankVideo,
    isEditorBusy,
    setIsEditorBusy,
    outlineList,
    layoutsData,
    isOpenOutlineModal,
    setIsOpenOutlineModal,
    videoErrors,
    setVideoErrors,
    isOpenErrorsModal,
    setIsOpenErrorsModal,
    gptLoading,
    setGptLoading,
    gptInputValue,
    setGptInputValue,
    isSwitching,
    setIsSwitching,
    customPromptModal,
    setCustomPromptModal,
    editingSlide,
    setEditingSlide,
    openImageIndex,
    setOpenImageIndex,
    isOpenTemplatesModal,
    setIsOpenTemplatesModal,
    changesHistory,
    undoLastChanges,
    updateVideo,
    saveVideo,
    validateAndGenerateVideo,
    handleKeyDownContainerContent,
    saveTextEditorChanges,
    markSlideStatusAsStory,
    handleClickField,
    handleSlideEnhancementsMenu,
    handleSubmitGptInput,
    applyVideoEnhancement,
    applySlideEnhancement,
    checkEmptySlide,
    generateModalFooter,
    generateVideo,
    ensureVideoSaved,
    updateCanvasesInStoryMode,
    updateCanvasInStoryMode,
    textEditorScrollbarRef,
  } = useVideoStory()

  if (!video) return <Spin style={{ width: '100%', marginTop: 50 }} />

  if (['rendering', 'validating'].includes(video.status))
    return (
      <Result
        icon={<CoffeeOutlined style={{ color: '#999999' }} />}
        title="This video is rendering now"
        subTitle="You will be able to edit video story after render completion."
        style={{ marginTop: '10%' }}
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    )

  return (
    <Layout className="video-story">
      <VideoHeader
        video={video}
        videoSavingStatus={videoSavingStatus}
        isVideoGenerating={isVideoGenerating}
        changesHistory={changesHistory}
        isSwitching={isSwitching}
        setIsSwitching={setIsSwitching}
        undoLastChanges={undoLastChanges}
        updateVideo={updateVideo}
        saveVideo={saveVideo}
        validateAndGenerateVideo={validateAndGenerateVideo}
        setIsOpenTemplatesModal={setIsOpenTemplatesModal}
        isEditorBusy={isEditorBusy}
      />
      <Layout className="video-story-container">
        <Sidebar />
        <Layout className="video-story-content">
          <Scrollbars
            ref={textEditorScrollbarRef}
            autoHeight
            autoHeightMin={500}
            autoHeightMax={`calc(100vh - 248px)`}
            className="text-editor-scrollbar scrollbar"
          >
            <div style={{ minHeight: `calc(100vh - 248px + 195px)` }}>
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                className="text-editor"
                onKeyDown={handleKeyDownContainerContent}
              >
                {video?.slides.map((slide, i) => (
                  <SlideItem
                    key={slide.id}
                    index={i}
                    video={video}
                    slide={slide}
                    videoErrors={videoErrors}
                    gptLoading={gptLoading}
                    layoutsData={layoutsData}
                    textEditorScrollbarRef={textEditorScrollbarRef}
                    updateVideo={updateVideo}
                    setOpenImageIndex={setOpenImageIndex}
                    setEditingSlide={setEditingSlide}
                    markSlideStatusAsStory={markSlideStatusAsStory}
                    handleClickField={handleClickField}
                    handleSlideEnhancementsMenu={handleSlideEnhancementsMenu}
                    checkEmptySlide={checkEmptySlide}
                    isEditorBusy={isEditorBusy}
                    updateCanvasInStoryMode={updateCanvasInStoryMode}
                    setIsEditorBusy={setIsEditorBusy}
                    saveTextEditorChanges={saveTextEditorChanges}
                  />
                ))}
              </div>
            </div>
          </Scrollbars>
          <TopicInput
            video={video}
            isEditorBusy={isEditorBusy}
            gptLoading={gptLoading}
            isBlankVideo={isBlankVideo}
            gptInputValue={gptInputValue}
            setGptInputValue={setGptInputValue}
            applyVideoEnhancement={applyVideoEnhancement}
            handleSubmitGptInput={handleSubmitGptInput}
          />
        </Layout>
      </Layout>
      <OutlineModal
        outlineList={outlineList}
        isOpenOutlineModal={isOpenOutlineModal}
        setIsOpenOutlineModal={setIsOpenOutlineModal}
        gptLoading={gptLoading}
        setGptLoading={setGptLoading}
        setGptInputValue={setGptInputValue}
        handleSubmitGptInput={handleSubmitGptInput}
        applyVideoEnhancement={applyVideoEnhancement}
        handleClickField={handleClickField}
      />
      <SlideCountModal
        customPromptModal={customPromptModal}
        setCustomPromptModal={setCustomPromptModal}
        gptLoading={gptLoading}
        setGptLoading={setGptLoading}
        applySlideEnhancement={applySlideEnhancement}
      />
      <ImageModal
        video={video}
        editingSlide={editingSlide}
        setEditingSlide={setEditingSlide}
        openImageIndex={openImageIndex}
        setOpenImageIndex={setOpenImageIndex}
        updateVideo={updateVideo}
        markSlideStatusAsStory={markSlideStatusAsStory}
      />
      <ValidationModal
        isOpenErrorsModal={isOpenErrorsModal}
        setIsOpenErrorsModal={setIsOpenErrorsModal}
        gptLoading={gptLoading}
        setGptLoading={setGptLoading}
        videoErrors={videoErrors}
        setVideoErrors={setVideoErrors}
        isVideoGenerating={isVideoGenerating}
        generateVideo={generateVideo}
        ensureVideoSaved={ensureVideoSaved}
      />
      <TemplatesModal
        video={video}
        generateModalFooter={generateModalFooter}
        isOpenTemplatesModal={isOpenTemplatesModal}
        setIsOpenTemplatesModal={setIsOpenTemplatesModal}
        setIsSwitching={setIsSwitching}
        validateAndGenerateVideo={validateAndGenerateVideo}
        updateVideo={updateVideo}
        updateCanvasesInStoryMode={updateCanvasesInStoryMode}
        setIsEditorBusy={setIsEditorBusy}
      />
    </Layout>
  )
}

export default VideoStory
