import { useStore } from '../../store'

export const ROLES = [
  { role: 'admin', tooltip: 'Account owner or super admin, can do everything.' },
  { role: 'user', tooltip: 'Can do everything except admin actions (SSO, billing, API keys etc).' },
  {
    role: 'editor',
    tooltip:
      'Can perform all non-admin actions (same as user) but can`t render videos and spend minutes in the account.',
  },
  {
    role: 'reviewer',
    tooltip: 'Can browse, review and comment on videos and templates but can`t edit and render videos.',
  },
  {
    role: 'locked',
    tooltip:
      'Can`t navigate the app and able to see only specific video page and it`s preview page. You need to redirect such user to specific video page. This role is used for deep integrations.',
  },
]

export const useUserState = () => {
  const authStore = useStore((stores) => stores.authStore)
  return { authStore }
}
