export const interactiveObjects = {
  'button-gradient': {
    type: 'group',
    id: 513841480401,
    left: 100,
    top: 100,
    width: 144.17237415,
    height: 45.83411231,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'rect',
        id: 54818191435,
        left: -72.08618707,
        top: -22.91705616,
        width: 143.17237415,
        height: 44.83411231,
        fill: {
          type: 'linear',
          coords: {
            x1: 0,
            y1: 0,
            x2: 0.79,
            y2: 0.44,
          },
          colorStops: [
            {
              offset: 0,
              color: '#1c128b',
            },
            {
              offset: 1,
              color: '#e548ff',
            },
          ],
          offsetX: 0,
          offsetY: 0,
          gradientUnits: 'percentage',
          gradientTransform: null,
        },
        rx: 20,
        ry: 20,
      },
      {
        type: 'textbox',
        id: 829746973164,
        left: -53.83333333,
        top: -9.54,
        width: 105.66666667,
        height: 18.08,
        fill: '#ffffff',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
        fontSize: 16,
        text: 'Get started',
        textAlign: 'center',
        styles: [],
        meta: {
          splittedText: 'Get started',
        },
      },
    ],
  },
  'button-primary': {
    type: 'group',
    left: 100,
    top: 100,
    width: 149.56018647,
    height: 45.83411231,
    strokeWidth: 0,
    id: 1211153580104,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'rect',
        left: -74.78009323,
        top: -22.91705615,
        width: 148.56018647,
        height: 44.83411231,
        fill: '#486dff',
        rx: 4,
        ry: 4,
        id: 433568783358,
      },
      {
        type: 'textbox',
        left: -58.79594508,
        top: -10.07405462,
        width: 78.35676093,
        height: 18.08,
        fill: '#ffffff',
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
        fontSize: 16,
        text: 'See more',
        styles: [],
        id: 1288426117205,
        meta: {
          splittedText: 'See more',
        },
      },
      {
        type: 'path',
        left: 30.55737233,
        top: -8.56088963,
        width: 13.50000004,
        height: 7.363962,
        fill: 'white',
        scaleX: 1.84232822,
        scaleY: 1.84232822,
        id: 737795019980,
        path: [
          ['M', 13.3536, 4.35355],
          ['C', 13.5488, 4.15829, 13.5488, 3.84171, 13.3536, 3.64645],
          ['L', 10.1716, 0.464467],
          ['C', 9.97631, 0.269205, 9.65973, 0.269205, 9.46447, 0.464467],
          ['C', 9.2692, 0.659729, 9.2692, 0.976312, 9.46447, 1.17157],
          ['L', 12.2929, 4],
          ['L', 9.46447, 6.82843],
          ['C', 9.2692, 7.02369, 9.2692, 7.34027, 9.46447, 7.53553],
          ['C', 9.65973, 7.7308, 9.97631, 7.7308, 10.1716, 7.53553],
          ['L', 13.3536, 4.35355],
          ['Z'],
          ['M', -4.37114e-8, 4.5],
          ['L', 13, 4.5],
          ['L', 13, 3.5],
          ['L', 4.37114e-8, 3.5],
          ['L', -4.37114e-8, 4.5],
          ['Z'],
        ],
      },
    ],
  },
  'button-youtube': {
    type: 'group',
    left: 100,
    top: 100,
    width: 45.83333439,
    height: 45.83333439,
    id: 1323753674870,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'circle',
        left: -22.9166672,
        top: -22.9166672,
        width: 100,
        height: 100,
        fill: '#ff0000',
        scaleX: 0.45379539,
        scaleY: 0.45379539,
        radius: 50,
        id: 1215494126463,
      },
      {
        type: 'path',
        left: -12.73516727,
        top: -9.11330921,
        width: 22.01389044,
        height: 15.4687824,
        fill: '#ffffff',
        scaleX: 1.10673746,
        scaleY: 1.10673746,
        id: 782275921925,
        path: [
          ['M', 23.001, 9.7095],
          ['C', 23.0505, 8.27815, 22.7374, 6.85751, 22.091, 5.5795],
          ['C', 21.6524, 5.05509, 21.0437, 4.70121, 20.371, 4.5795],
          ['C', 17.5885, 4.32702, 14.7945, 4.22354, 12.001, 4.2695],
          ['C', 9.21765, 4.22145, 6.43371, 4.3216, 3.661, 4.5695],
          ['C', 3.11282, 4.66921, 2.60552, 4.92634, 2.201, 5.3095],
          ['C', 1.301, 6.1395, 1.201, 7.5595, 1.101, 8.7595],
          ['C', 0.955916, 10.9171, 0.955916, 13.0819, 1.101, 15.2395],
          ['C', 1.12993, 15.9149, 1.2305, 16.5853, 1.401, 17.2395],
          ['C', 1.52158, 17.7446, 1.76553, 18.2118, 2.111, 18.5995],
          ['C', 2.51827, 19.003, 3.03739, 19.2747, 3.601, 19.3795],
          ['C', 5.75692, 19.6456, 7.92922, 19.7559, 10.101, 19.7095],
          ['C', 13.601, 19.7595, 16.671, 19.7095, 20.301, 19.4295],
          ['C', 20.8785, 19.3311, 21.4122, 19.059, 21.831, 18.6495],
          ['C', 22.111, 18.3694, 22.3201, 18.0266, 22.441, 17.6495],
          ['C', 22.7986, 16.5521, 22.9743, 15.4036, 22.961, 14.2495],
          ['C', 23.001, 13.6895, 23.001, 10.3095, 23.001, 9.7095],
          ['Z'],
          ['M', 9.741, 14.8495],
          ['L', 9.741, 8.6595],
          ['L', 15.661, 11.7695],
          ['C', 14.001, 12.6895, 11.811, 13.7295, 9.741, 14.8495],
          ['Z'],
        ],
      },
    ],
  },
  'button-web': {
    type: 'group',
    left: 100,
    top: 100,
    width: 45.83333439,
    height: 45.83333439,
    id: 153546121532,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'circle',
        left: -22.9166672,
        top: -22.9166672,
        width: 100,
        height: 100,
        fill: '#0d7eff',
        scaleX: 0.45379539,
        scaleY: 0.45379539,
        radius: 50,
        id: 1197836452451,
      },
      {
        type: 'path',
        left: -13.10011432,
        top: -13.08847999,
        width: 19.960315,
        height: 19.9417,
        fill: 'white',
        scaleX: 1.24999212,
        scaleY: 1.24999212,
        id: 1129828769365,
        path: [
          ['M', 21.4092, 8.64013],
          ['C', 21.4092, 8.64013, 21.4092, 8.64013, 21.4092, 8.59013],
          ['C', 20.7046, 6.66635, 19.4261, 5.00541, 17.7467, 3.83199],
          ['C', 16.0673, 2.65857, 14.068, 2.0293, 12.0192, 2.0293],
          ['C', 9.97047, 2.0293, 7.97115, 2.65857, 6.29173, 3.83199],
          ['C', 4.6123, 5.00541, 3.33382, 6.66635, 2.62922, 8.59013],
          ['C', 2.62922, 8.59013, 2.62922, 8.59013, 2.62922, 8.64013],
          ['C', 1.84234, 10.811, 1.84234, 13.1892, 2.62922, 15.3601],
          ['C', 2.62922, 15.3601, 2.62922, 15.3601, 2.62922, 15.4101],
          ['C', 3.33382, 17.3339, 4.6123, 18.9948, 6.29173, 20.1683],
          ['C', 7.97115, 21.3417, 9.97047, 21.971, 12.0192, 21.971],
          ['C', 14.068, 21.971, 16.0673, 21.3417, 17.7467, 20.1683],
          ['C', 19.4261, 18.9948, 20.7046, 17.3339, 21.4092, 15.4101],
          ['C', 21.4092, 15.4101, 21.4092, 15.4101, 21.4092, 15.3601],
          ['C', 22.1961, 13.1892, 22.1961, 10.811, 21.4092, 8.64013],
          ['Z'],
          ['M', 4.25922, 14.0001],
          ['C', 3.91245, 12.6893, 3.91245, 11.3109, 4.25922, 10.0001],
          ['L', 6.11922, 10.0001],
          ['C', 5.95925, 11.3287, 5.95925, 12.6716, 6.11922, 14.0001],
          ['L', 4.25922, 14.0001],
          ['Z'],
          ['M', 5.07922, 16.0001],
          ['L', 6.47922, 16.0001],
          ['C', 6.71394, 16.892, 7.04943, 17.7542, 7.47922, 18.5701],
          ['C', 6.49852, 17.9021, 5.67872, 17.0242, 5.07922, 16.0001],
          ['Z'],
          ['M', 6.47922, 8.00013],
          ['L', 5.07922, 8.00013],
          ['C', 5.67009, 6.97921, 6.47941, 6.10159, 7.44922, 5.43013],
          ['C', 7.02977, 6.24737, 6.70436, 7.10954, 6.47922, 8.00013],
          ['Z'],
          ['M', 10.9992, 19.7001],
          ['C', 9.77098, 18.7988, 8.90837, 17.4854, 8.56922, 16.0001],
          ['L', 10.9992, 16.0001],
          ['L', 10.9992, 19.7001],
          ['Z'],
          ['M', 10.9992, 14.0001],
          ['L', 8.13922, 14.0001],
          ['C', 7.95261, 12.6733, 7.95261, 11.3269, 8.13922, 10.0001],
          ['L', 10.9992, 10.0001],
          ['L', 10.9992, 14.0001],
          ['Z'],
          ['M', 10.9992, 8.00013],
          ['L', 8.56922, 8.00013],
          ['C', 8.90837, 6.51489, 9.77098, 5.20144, 10.9992, 4.30013],
          ['L', 10.9992, 8.00013],
          ['Z'],
          ['M', 18.9192, 8.00013],
          ['L', 17.5192, 8.00013],
          ['C', 17.2845, 7.10828, 16.949, 6.24606, 16.5192, 5.43013],
          ['C', 17.4999, 6.0982, 18.3197, 6.97607, 18.9192, 8.00013],
          ['Z'],
          ['M', 12.9992, 4.30013],
          ['C', 14.2275, 5.20144, 15.0901, 6.51489, 15.4292, 8.00013],
          ['L', 12.9992, 8.00013],
          ['L', 12.9992, 4.30013],
          ['Z'],
          ['M', 12.9992, 19.7001],
          ['L', 12.9992, 16.0001],
          ['L', 15.4292, 16.0001],
          ['C', 15.0901, 17.4854, 14.2275, 18.7988, 12.9992, 19.7001],
          ['Z'],
          ['M', 15.8592, 14.0001],
          ['L', 12.9992, 14.0001],
          ['L', 12.9992, 10.0001],
          ['L', 15.8592, 10.0001],
          ['C', 16.0458, 11.3269, 16.0458, 12.6733, 15.8592, 14.0001],
          ['Z'],
          ['M', 16.5492, 18.5701],
          ['C', 16.979, 17.7542, 17.3145, 16.892, 17.5492, 16.0001],
          ['L', 18.9492, 16.0001],
          ['C', 18.3497, 17.0242, 17.5299, 17.9021, 16.5492, 18.5701],
          ['Z'],
          ['M', 19.7392, 14.0001],
          ['L', 17.8792, 14.0001],
          ['C', 17.9605, 13.3366, 18.0006, 12.6686, 17.9992, 12.0001],
          ['C', 18.0003, 11.3316, 17.9602, 10.6637, 17.8792, 10.0001],
          ['L', 19.7392, 10.0001],
          ['C', 20.086, 11.3109, 20.086, 12.6893, 19.7392, 14.0001],
          ['Z'],
        ],
      },
    ],
  },
  'button-link-arrow': {
    type: 'group',
    left: 100,
    top: 100,
    width: 123.00747491,
    height: 19.08,
    id: 639758221459,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'textbox',
        left: -61.50373745,
        top: -9.54,
        width: 103,
        height: 18.08,
        fill: '#0000ff',
        fontFamily: 'Poppins',
        fontSize: 16,
        text: 'Visit website',
        styles: [],
        id: 860776893990,
        meta: {
          splittedText: 'Visit website',
        },
      },
      {
        type: 'path',
        left: 51.13627381,
        top: -7.55394564,
        width: 4.16443,
        height: 7.02768,
        fill: '#0000ff',
        scaleX: 2.00747491,
        scaleY: 2.00747491,
        id: 1047790301455,
        path: [
          ['M', 6.11183, 4.66752],
          ['C', 5.98766, 4.79242, 5.91797, 4.96139, 5.91797, 5.13752],
          ['C', 5.91797, 5.31364, 5.98766, 5.48261, 6.11183, 5.60751],
          ['L', 8.47183, 8.00085],
          ['L', 6.11183, 10.3608],
          ['C', 5.98766, 10.4858, 5.91797, 10.6547, 5.91797, 10.8308],
          ['C', 5.91797, 11.007, 5.98766, 11.1759, 6.11183, 11.3008],
          ['C', 6.17381, 11.3633, 6.24754, 11.4129, 6.32878, 11.4468],
          ['C', 6.41002, 11.4806, 6.49716, 11.498, 6.58516, 11.498],
          ['C', 6.67317, 11.498, 6.76031, 11.4806, 6.84155, 11.4468],
          ['C', 6.92279, 11.4129, 6.99652, 11.3633, 7.0585, 11.3008],
          ['L', 9.88516, 8.47418],
          ['C', 9.94765, 8.41221, 9.99725, 8.33847, 10.0311, 8.25723],
          ['C', 10.0649, 8.17599, 10.0824, 8.08886, 10.0824, 8.00085],
          ['C', 10.0824, 7.91284, 10.0649, 7.8257, 10.0311, 7.74446],
          ['C', 9.99725, 7.66322, 9.94765, 7.58949, 9.88516, 7.52751],
          ['L', 7.0585, 4.66752],
          ['C', 6.99652, 4.60503, 6.92279, 4.55543, 6.84155, 4.52159],
          ['C', 6.76031, 4.48774, 6.67317, 4.47032, 6.58516, 4.47032],
          ['C', 6.49716, 4.47032, 6.41002, 4.48774, 6.32878, 4.52159],
          ['C', 6.24754, 4.55543, 6.17381, 4.60503, 6.11183, 4.66752],
          ['Z'],
        ],
      },
    ],
  },
  'button-link': {
    type: 'group',
    left: 100,
    top: 100,
    width: 60.16459663,
    height: 19.08,
    id: 812462542252,
    meta: { interactivity: { type: 'slide', slide: null } },
    objects: [
      {
        type: 'textbox',
        left: -30.08229831,
        top: -9.54,
        width: 29.32800049,
        height: 18.08,
        fill: '#0000ff',
        fontFamily: 'Poppins',
        fontSize: 16,
        text: 'Link',
        styles: [],
        id: 35416806111,
        meta: {
          splittedText: 'Link',
        },
      },
      {
        type: 'path',
        left: 13.49363225,
        top: -8.54555508,
        width: 13.24412962,
        height: 13.24412944,
        fill: '#0000ff',
        scaleX: 1.16459668,
        scaleY: 1.16459668,
        id: 1688921568091,
        path: [
          ['M', 8.57192, 10.2594],
          ['L', 5.98526, 12.8461],
          ['C', 5.67208, 13.1483, 5.25383, 13.3172, 4.81859, 13.3172],
          ['C', 4.38335, 13.3172, 3.9651, 13.1483, 3.65192, 12.8461],
          ['C', 3.49833, 12.6931, 3.37645, 12.5113, 3.29329, 12.3111],
          ['C', 3.21013, 12.1109, 3.16732, 11.8962, 3.16732, 11.6794],
          ['C', 3.16732, 11.4626, 3.21013, 11.248, 3.29329, 11.0478],
          ['C', 3.37645, 10.8476, 3.49833, 10.6657, 3.65192, 10.5127],
          ['L', 6.23859, 7.92608],
          ['C', 6.36412, 7.80054, 6.43465, 7.63028, 6.43465, 7.45275],
          ['C', 6.43465, 7.27521, 6.36412, 7.10495, 6.23859, 6.97941],
          ['C', 6.11305, 6.85388, 5.94279, 6.78335, 5.76526, 6.78335],
          ['C', 5.58772, 6.78335, 5.41746, 6.85388, 5.29192, 6.97941],
          ['L', 2.70526, 9.57275],
          ['C', 2.18416, 10.1399, 1.90234, 10.8865, 1.91864, 11.6566],
          ['C', 1.93494, 12.4266, 2.24811, 13.1606, 2.79274, 13.7053],
          ['C', 3.33738, 14.2499, 4.07136, 14.5631, 4.84142, 14.5794],
          ['C', 5.61148, 14.5957, 6.35806, 14.3138, 6.92526, 13.7927],
          ['L', 9.51859, 11.2061],
          ['C', 9.64412, 11.0805, 9.71465, 10.9103, 9.71465, 10.7327],
          ['C', 9.71465, 10.5552, 9.64412, 10.3849, 9.51859, 10.2594],
          ['C', 9.39305, 10.1339, 9.22279, 10.0634, 9.04526, 10.0634],
          ['C', 8.86772, 10.0634, 8.69746, 10.1339, 8.57192, 10.2594],
          ['Z'],
          ['M', 14.2919, 2.20608],
          ['C', 13.7311, 1.64875, 12.9726, 1.33594, 12.1819, 1.33594],
          ['C', 11.3913, 1.33594, 10.6327, 1.64875, 10.0719, 2.20608],
          ['L', 7.47859, 4.79275],
          ['C', 7.41643, 4.8549, 7.36712, 4.9287, 7.33348, 5.00991],
          ['C', 7.29984, 5.09113, 7.28253, 5.17817, 7.28253, 5.26608],
          ['C', 7.28253, 5.35399, 7.29984, 5.44103, 7.33348, 5.52224],
          ['C', 7.36712, 5.60346, 7.41643, 5.67725, 7.47859, 5.73941],
          ['C', 7.54075, 5.80157, 7.61454, 5.85088, 7.69576, 5.88452],
          ['C', 7.77697, 5.91816, 7.86402, 5.93547, 7.95192, 5.93547],
          ['C', 8.03983, 5.93547, 8.12687, 5.91816, 8.20809, 5.88452],
          ['C', 8.2893, 5.85088, 8.3631, 5.80157, 8.42526, 5.73941],
          ['L', 11.0119, 3.15275],
          ['C', 11.3251, 2.8505, 11.7434, 2.68158, 12.1786, 2.68158],
          ['C', 12.6138, 2.68158, 13.0321, 2.8505, 13.3453, 3.15275],
          ['C', 13.4989, 3.30574, 13.6207, 3.48755, 13.7039, 3.68776],
          ['C', 13.787, 3.88796, 13.8299, 4.10262, 13.8299, 4.31941],
          ['C', 13.8299, 4.5362, 13.787, 4.75086, 13.7039, 4.95107],
          ['C', 13.6207, 5.15127, 13.4989, 5.33309, 13.3453, 5.48608],
          ['L', 10.7586, 8.07275],
          ['C', 10.6961, 8.13472, 10.6465, 8.20846, 10.6127, 8.28969],
          ['C', 10.5788, 8.37093, 10.5614, 8.45807, 10.5614, 8.54608],
          ['C', 10.5614, 8.63409, 10.5788, 8.72122, 10.6127, 8.80246],
          ['C', 10.6465, 8.8837, 10.6961, 8.95744, 10.7586, 9.01941],
          ['C', 10.8206, 9.0819, 10.8943, 9.13149, 10.9755, 9.16534],
          ['C', 11.0568, 9.19919, 11.1439, 9.21661, 11.2319, 9.21661],
          ['C', 11.3199, 9.21661, 11.4071, 9.19919, 11.4883, 9.16534],
          ['C', 11.5695, 9.13149, 11.6433, 9.0819, 11.7053, 9.01941],
          ['L', 14.2919, 6.42608],
          ['C', 14.8493, 5.86527, 15.1621, 5.10673, 15.1621, 4.31608],
          ['C', 15.1621, 3.52543, 14.8493, 2.76689, 14.2919, 2.20608],
          ['Z'],
          ['M', 6.38526, 10.1127],
          ['C', 6.44755, 10.1745, 6.52143, 10.2234, 6.60265, 10.2566],
          ['C', 6.68388, 10.2898, 6.77085, 10.3066, 6.85859, 10.3061],
          ['C', 6.94633, 10.3066, 7.0333, 10.2898, 7.11453, 10.2566],
          ['C', 7.19575, 10.2234, 7.26963, 10.1745, 7.33192, 10.1127],
          ['L', 10.6119, 6.83275],
          ['C', 10.7375, 6.70721, 10.808, 6.53695, 10.808, 6.35941],
          ['C', 10.808, 6.18188, 10.7375, 6.01161, 10.6119, 5.88608],
          ['C', 10.4864, 5.76054, 10.3161, 5.69002, 10.1386, 5.69002],
          ['C', 9.96105, 5.69002, 9.79079, 5.76054, 9.66526, 5.88608],
          ['L', 6.38526, 9.16608],
          ['C', 6.32277, 9.22805, 6.27317, 9.30179, 6.23933, 9.38303],
          ['C', 6.20548, 9.46427, 6.18806, 9.5514, 6.18806, 9.63941],
          ['C', 6.18806, 9.72742, 6.20548, 9.81456, 6.23933, 9.8958],
          ['C', 6.27317, 9.97704, 6.32277, 10.0508, 6.38526, 10.1127],
          ['Z'],
        ],
      },
    ],
  },
}

export const interactiveQuestionsData = {
  vertical: {
    type: 'question',
    left: 98.09002735,
    top: 35.22511263,
    width: 444.32661229,
    height: 288.2719653,
    strokeWidth: 0,
    id: 1286068700399,
    layoutType: 'vertical',
    feedback: false,
    onCorrect: null,
    onWrong: null,
    animation: { startScene: 'end', type: 'fade_in' },
    objects: [
      {
        type: 'rect',
        left: -222.16330615,
        top: -144.13598265,
        width: 443.32661229,
        height: 287.2719653,
        fill: '#000000',
        strokeWidth: 1,
        strokeUniform: true,
        rx: 15,
        ry: 15,
        id: 227364710963,
        question: { element: 'background' },
      },
      {
        type: 'textbox',
        left: -159.42000017,
        top: -116.61601771,
        width: 318.16666667,
        height: 34.1712,
        fill: '#141414',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14,
        text: 'Create a question that can have one\nor multiple right answers',
        textAlign: 'center',
        styles: [],
        id: 1002124440319,
        meta: {
          splittedText: 'Create a question that can have one\nor multiple right answers',
        },
        question: { element: 'question' },
      },
      {
        type: 'rect',
        left: -162.56324687,
        top: -56.16571511,
        width: 324,
        height: 146,
        fill: 'rgba(0, 0, 0, 0)',
        strokeWidth: 0,
        strokeUniform: true,
        id: 503453606817,
        question: { element: 'answers_container' },
      },
      {
        type: 'group',
        left: -162.25333349,
        top: -55.27227298,
        width: 324,
        height: 146,
        strokeWidth: 0,
        id: 596273216791,
        question: { element: 'answers_group' },
        objects: [
          {
            type: 'group',
            left: -161.98593238,
            top: -73.45558885,
            width: 324,
            height: 43,
            strokeWidth: 0,
            id: 1096441546510,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -162.18617719,
                top: -21.50556368,
                width: 323,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 29652879439,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -149.46675252,
                top: -10.40218609,
                width: 24,
                height: 24,
                radius: 9,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#4868ff',
                strokeWidth: 6,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 311333307954,
                question: { element: 'answer_checkbox', state: 'checked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -120.44108733,
                top: -6.71500001,
                width: 266.35442051,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                textAlign: 'left',
                styles: [],
                id: 1249469397914,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
          {
            type: 'group',
            left: -162.18617719,
            top: -21.95558885,
            width: 324,
            height: 43,
            strokeWidth: 0,
            id: 1366560400407,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -162.18617719,
                top: -21.50556368,
                width: 323,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 1636352419,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -149.46675252,
                top: -10.40218609,
                width: 24,
                height: 24,
                radius: 11,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#bdbdbd',
                strokeWidth: 2,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 1446412217178,
                question: { element: 'answer_checkbox', state: 'unchecked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -120.44108733,
                top: -6.71500001,
                width: 262.18775384,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                styles: [],
                id: 645959279125,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
          {
            type: 'group',
            left: -162.18617719,
            top: 29.44446148,
            width: 324,
            height: 43,
            strokeWidth: 0,
            id: 1689120138507,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -162.18617719,
                top: -21.50556368,
                width: 323,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 1595502381893,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -149.46675252,
                top: -10.40218609,
                width: 24,
                height: 24,
                radius: 11,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#bdbdbd',
                strokeWidth: 2,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 729732134029,
                question: { element: 'answer_checkbox', state: 'unchecked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -120.44108735,
                top: -6.71500001,
                width: 267.33490998,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                styles: [],
                id: 588185403525,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
        ],
      },
      {
        type: 'group',
        left: 172.44,
        top: -56.16571511,
        width: 5.4979161,
        height: 147,
        strokeWidth: 0,
        id: 786604714957,
        question: { element: 'answers_scroll' },
        objects: [
          {
            type: 'rect',
            left: 2.74895805,
            top: 74.62204573,
            width: 5.4979161,
            height: 150,
            fill: '#d9d9d9',
            strokeWidth: 0,
            strokeUniform: true,
            angle: 180,
            flipX: false,
            flipY: true,
            opacity: 1,
            rx: 4,
            ry: 4,
            id: 1497828662171,
            question: { element: 'scroll_track' },
          },
          {
            type: 'rect',
            left: 1.98464585,
            top: 74.62204573,
            width: 3.96929169,
            height: 150,
            fill: '#31353b',
            strokeWidth: 0,
            strokeUniform: true,
            angle: 180,
            flipX: false,
            flipY: true,
            opacity: 1,
            rx: 2,
            ry: 2,
            id: 289403146398,
            question: { element: 'scroll_thumb' },
          },
        ],
      },
      {
        type: 'group',
        id: 513841480401,
        left: 131.26,
        top: 100.13,
        width: 79,
        height: 32,
        question: { element: 'button_send' },
        objects: [
          {
            type: 'rect',
            id: 54818191435,
            left: -39.5,
            top: -16,
            width: 79,
            height: 32,
            fill: '#4868ff',
            rx: 6,
            ry: 6,
          },
          {
            type: 'textbox',
            id: 829746973164,
            left: -24.5,
            top: -8.5,
            width: 47,
            height: 16,
            fill: '#ffffff',
            fontFamily: 'DM Sans',
            fontSize: 14,
            text: 'Submit',
            textAlign: 'center',
            styles: [],
            meta: {
              splittedText: 'Submit',
            },
          },
        ],
      },
    ],
  },
  horizontal: {
    type: 'question',
    left: 31.54739344,
    top: 59,
    width: 573.70500736,
    height: 242.35588917,
    strokeWidth: 0,
    id: 435192946619,
    layoutType: 'horizontal',
    feedback: false,
    onCorrect: null,
    onWrong: null,
    animation: { startScene: 'end', type: 'fade_in' },
    objects: [
      {
        type: 'rect',
        left: -286.85250368,
        top: -121.17794459,
        width: 572.70500736,
        height: 241.35588917,
        fill: '#ffffff',
        strokeWidth: 1,
        strokeUniform: true,
        rx: 15,
        ry: 15,
        id: 227364710963,
        question: { element: 'background' },
      },
      {
        type: 'textbox',
        left: -206.89965523,
        top: -52.5,
        width: 411.70452358,
        height: 34.1712,
        fill: '#141414',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14,
        text: 'Create a question that can have one\nor multiple right answers',
        textAlign: 'center',
        styles: [],
        id: 1002124440319,
        meta: {
          splittedText: 'Create a question that can have one\nor multiple right answers',
        },
        question: { element: 'question' },
      },
      {
        type: 'rect',
        left: -254.8852296,
        top: 6.09444437,
        width: 511,
        height: 43,
        fill: 'rgba(0, 0, 0, 0)',
        strokeWidth: 0,
        strokeUniform: true,
        id: 1582597336082,
        question: { element: 'answers_container' },
      },
      {
        type: 'group',
        left: -254.2464092,
        top: 0.08595789,
        width: 511,
        height: 43,
        strokeWidth: 0,
        id: 14262238568,
        question: { element: 'answers_group' },
        objects: [
          {
            type: 'group',
            left: -255.5,
            top: -22,
            width: 163,
            height: 43,
            strokeWidth: 0,
            id: 1426579880870,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -81.89989712,
                top: -21.50556368,
                width: 162,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 29652879439,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -70.96671819,
                top: -10.40218608,
                width: 24,
                height: 24,
                radius: 9,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#4868ff',
                strokeWidth: 6,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 311333307954,
                question: { element: 'answer_checkbox', state: 'checked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -42.15480725,
                top: -6.71499999,
                width: 115.91667458,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                styles: [],
                id: 1249469397914,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
          {
            type: 'group',
            left: -81.89989712,
            top: -22,
            width: 163,
            height: 43,
            fill: 'rgb(0,0,0)',
            strokeWidth: 0,
            id: 267562055625,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -81.89989712,
                top: -21.50556368,
                width: 162,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 1651999445498,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -70.96671819,
                top: -10.40218608,
                width: 24,
                height: 24,
                radius: 11,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#bdbdbd',
                strokeWidth: 2,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 1446412217178,
                question: { element: 'answer_checkbox', state: 'unchecked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -42.95485878,
                top: -6.71499999,
                width: 115.91667458,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                styles: [],
                id: 1256746881317,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
          {
            type: 'group',
            left: 92.30005133,
            top: -22,
            width: 163,
            height: 43,
            strokeWidth: 0,
            id: 780935124470,
            question: { element: 'answer' },
            objects: [
              {
                type: 'rect',
                left: -81.89989712,
                top: -21.50556368,
                width: 162,
                height: 42,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#d9d9d9',
                strokeWidth: 2,
                strokeUniform: true,
                rx: 6,
                ry: 6,
                id: 539231160304,
                question: { element: 'answer_background' },
              },
              {
                type: 'circle',
                left: -70.96671819,
                top: -10.40218608,
                width: 24,
                height: 24,
                radius: 11,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: '#bdbdbd',
                strokeWidth: 2,
                scaleX: 0.86684884,
                scaleY: 0.86684884,
                id: 587753025109,
                question: { element: 'answer_checkbox', state: 'unchecked', type: 'radio' },
              },
              {
                type: 'textbox',
                left: -42.15480724,
                top: -6.71499999,
                width: 115.91667458,
                height: 12.43,
                fill: '#141414',
                fontFamily: 'Poppins',
                fontSize: 11,
                text: 'Provide an answer',
                styles: [],
                id: 1060151471394,
                meta: {
                  splittedText: 'Provide an answer',
                },
                question: { element: 'answer_text' },
              },
            ],
          },
        ],
      },
      {
        type: 'group',
        left: 267.93447816,
        top: 6.09444437,
        width: 5.4979161,
        height: 45,
        strokeWidth: 0,
        id: 93842269129,
        question: { element: 'answers_scroll' },
        objects: [
          {
            type: 'rect',
            left: 2.74895805,
            top: 22.5,
            width: 5.4979161,
            height: 45,
            fill: '#d9d9d9',
            strokeWidth: 0,
            strokeUniform: true,
            angle: 180,
            flipX: false,
            flipY: true,
            rx: 4,
            ry: 4,
            id: 1053052339737,
            question: { element: 'scroll_track' },
          },
          {
            type: 'rect',
            left: 1.98464585,
            top: 22.5,
            width: 3.96929169,
            height: 45,
            fill: '#31353b',
            strokeWidth: 0,
            strokeUniform: true,
            angle: 180,
            flipX: false,
            flipY: true,
            rx: 2,
            ry: 2,
            id: 1194156090892,
            question: { element: 'scroll_thumb' },
          },
        ],
      },
      {
        type: 'group',
        id: 513841480401,
        left: 195.85,
        top: 77.175,
        width: 79,
        height: 32,
        question: { element: 'button_send' },
        objects: [
          {
            type: 'rect',
            id: 54818191435,
            left: -39.5,
            top: -16,
            width: 79,
            height: 32,
            fill: '#4868ff',
            rx: 6,
            ry: 6,
          },
          {
            type: 'textbox',
            id: 829746973164,
            left: -24.5,
            top: -8.5,
            width: 47,
            height: 16,
            fill: '#ffffff',
            fontFamily: 'DM Sans',
            fontSize: 14,
            text: 'Submit',
            textAlign: 'center',
            styles: [],
            meta: {
              splittedText: 'Submit',
            },
          },
        ],
      },
    ],
  },
}

export const checkboxes = {
  checked: {
    type: 'group',
    left: -150.14883107,
    top: -11.08426463,
    width: 25,
    height: 25,
    strokeWidth: 0,
    scaleX: 0.88674117,
    scaleY: 0.88674117,
    id: 311333307954,
    question: { element: 'answer_checkbox', state: 'checked' },
    objects: [
      {
        type: 'rect',
        left: -12.5,
        top: -12.5,
        width: 24,
        height: 24,
        fill: '#4868FF',
        strokeWidth: 1,
        scaleX: 1,
        scaleY: 1,
        rx: 6,
        ry: 6,
      },
      {
        type: 'path',
        left: -5.1679725,
        top: -3.9003925,
        width: 9.3333225,
        height: 6.7999925,
        fill: 'white',
        strokeWidth: 1,
        path: [
          ['M', 16.4654, 8.79961],
          ['C', 16.1987, 8.53294, 15.7987, 8.53294, 15.532, 8.79961],
          ['L', 10.532, 13.7996],
          ['L', 8.46536, 11.7329],
          ['C', 8.1987, 11.4663, 7.7987, 11.4663, 7.53203, 11.7329],
          ['C', 7.26536, 11.9996, 7.26536, 12.3996, 7.53203, 12.6663],
          ['L', 10.0654, 15.1996],
          ['C', 10.1987, 15.3329, 10.332, 15.3996, 10.532, 15.3996],
          ['C', 10.732, 15.3996, 10.8654, 15.3329, 10.9987, 15.1996],
          ['L', 16.4654, 9.73294],
          ['C', 16.732, 9.46628, 16.732, 9.06628, 16.4654, 8.79961],
          ['Z'],
        ],
      },
    ],
  },
  unchecked: {
    type: 'rect',
    left: -149.46675252,
    top: -10.40218609,
    width: 23,
    height: 23,
    fill: 'rgba(0, 0, 0, 0)',
    stroke: '#BDBDBD',
    strokeWidth: 1,
    scaleX: 0.86684884,
    scaleY: 0.86684884,
    rx: 5.5,
    ry: 5.5,
    id: 1446412217178,
    question: { element: 'answer_checkbox', state: 'unchecked' },
  },
}

export const questionThemes = {
  light: {
    backgroundColor: '#ffffff',
    textColor: '#141414',
    scrollbarTrackColor: '#d9d9d9',
    scrollbarTrackOpacity: 1,
    scrollbarThumbColor: '#31353b',
  },
  dark: {
    backgroundColor: '#141414',
    textColor: '#ffffff',
    scrollbarTrackColor: '#d9d9d9',
    scrollbarTrackOpacity: 0.47,
    scrollbarThumbColor: '#ffffff',
  },
  transparent_dark: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textColor: '#141414',
    scrollbarTrackColor: '#d9d9d9',
    scrollbarTrackOpacity: 0.47,
    scrollbarThumbColor: '#ffffff',
  },
  transparent_light: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textColor: '#ffffff',
    scrollbarTrackColor: '#d9d9d9',
    scrollbarTrackOpacity: 0.47,
    scrollbarThumbColor: '#ffffff',
  },
}

export const interactiveQuestion = (id) => {
  const [layoutType, theme, fontColor] = id.split('-')
  const questionData = interactiveQuestionsData[layoutType]
  const themeKey = fontColor ? `${theme}_${fontColor}` : theme
  questionData.theme = themeKey
  const { backgroundColor, textColor, scrollbarTrackColor, scrollbarTrackOpacity, scrollbarThumbColor } =
    questionThemes[themeKey]
  const backgroundObj = questionData.objects.find((obj) => obj.question.element === 'background')
  backgroundObj.fill = backgroundColor
  const questionTextObj = questionData.objects.find((obj) => obj.question.element === 'question')
  questionTextObj.fill = textColor
  const answersObjects = questionData.objects.find((obj) => obj.question.element === 'answers_group').objects
  answersObjects.forEach((answer) => {
    const textObj = answer.objects.find((obj) => obj.question.element === 'answer_text')
    textObj.fill = textColor
  })
  const scrollbarObject = questionData.objects.find((obj) => obj.question.element === 'answers_scroll')
  const [scrollTrack, scrollThumb] = scrollbarObject.objects
  scrollTrack.fill = scrollbarTrackColor
  scrollTrack.opacity = scrollbarTrackOpacity
  scrollThumb.fill = scrollbarThumbColor
  return questionData
}
