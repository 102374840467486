import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Dropdown, Button, Card, Spin, Alert, Space, Switch, Tooltip, Input, Popover, message, Layout } from 'antd'
import {
  DownloadOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  FileTextOutlined,
  CopyOutlined,
  TranslationOutlined,
  MoreOutlined,
  PlusOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import Icon from '../../components/Icon'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import thinkificLogo from '../../assets/images/thinkific-dark.svg'
import TranslateVideoModal from '../../components/TranslateVideoModal'
import { dynamicTitle, playerUrl } from '../../utils/config'
import PageHeader from '../../components/PageHeader/pageHeader'
import { goBack } from '../../utils/navigation'
import { Comments } from '../../components/Comments/comments'
import { ACCOUNT_PLAN } from '../../utils/constants'
import { requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'
import config from '../../utils/config'
import { track } from '../../utils/analytics'

import './preview.less'

const { Content, Sider } = Layout

const buildScormButton = (version, isAdvancedPlusPlan) => ({
  key: `download_scorm_package_${version}`,
  icon: <CloudDownloadOutlined />,
  label: (
    <>
      {' '}
      <Tooltip
        placement="top"
        overlayStyle={{ visibility: isAdvancedPlusPlan ? 'hidden' : 'visible' }}
        title="Upgrade to Enterprise plan to export SCORM packages"
      >
        Export SCORM {version}
      </Tooltip>
    </>
  ),
  disabled: !isAdvancedPlusPlan,
})

const Preview = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const authStore = useStore((stores) => stores.authStore)
  const { setHeader } = useStore((stores) => stores.domStore)
  const [video, setVideo] = useState()
  const [iframeRef, setIframeRef] = useState(null)
  const [isTranslateVideoModalOpen, setIsTranslateVideoModalOpen] = useState(false)

  const isEnterprisePlan = [ACCOUNT_PLAN.CUSTOM, ACCOUNT_PLAN.UNLIMITED].includes(authStore.user.account.plan)

  const videoSrc = useMemo(() => {
    const videoSrcMemoied = new URL(video?._id, config.playerUrl)
    if (localStorage.getItem('token')) {
      videoSrcMemoied.searchParams.set('token', localStorage.getItem('token'))
    }
    if (localStorage.getItem('subAccountId')) {
      videoSrcMemoied.searchParams.set('subAccountId', localStorage.getItem('subAccountId'))
    }
    return videoSrcMemoied
  }, [video?._id])

  const fetchVideo = async () => {
    setVideo(null)
    const v = await request({ method: 'get', url: `videos/${id}` })
    setVideo(v)
  }

  const tooglePublic = async (v) => {
    if (v.public) track('preview_share_make_public')
    setVideo({ ...video, public: v })
    await request({ method: 'patch', url: `videos/${id}`, data: { public: v } })
  }

  const downloadSubtitles = async () => {
    const link = document.createElement('a')
    link.href = video.data.subtitlesUrl
    link.setAttribute('download', 'subtitles.srt')
    link.click()
  }

  const downloadScormPackage = async (version = '2004v4') => {
    const response = await request({
      method: 'get',
      url: `/videos/${video._id}/scorm?version=${version}`,
      responseType: 'blob',
      passthroughResponse: true,
    })
    if (!response) return
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      response.headers['content-disposition'].split('filename=')[1] ?? `scorm_${version}.zip`,
    )
    link.click()
  }

  const getEmbedHtml = (video) =>
    `<iframe width="640" height="360" src="${playerUrl}/${video._id}"
    title="${
      dynamicTitle || 'Elai.io video player'
    }" frameborder="0" allow="autoplay" allowfullscreen scrolling="no"></iframe>`

  const resizeVideoContainer = () => {
    if (!iframeRef) return
    iframeRef.height = iframeRef.clientWidth / 1.777
  }

  useEffect(() => resizeVideoContainer(), [iframeRef])

  window.onresize = resizeVideoContainer

  useEffect(() => {
    track('preview_visit')
    setHeader({
      title: 'Home',
      icon: <Icon name="house" />,
      link: '/',
      extra: null,
    })
    fetchVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!video) return <Spin style={{ width: '100%', marginTop: 50 }} />

  const buttons = []
  const publicLink = `${window.location.origin}/v/${id}`
  const embedHtml = getEmbedHtml(video)

  const duplicateVideo = async () => {
    const id = await requestDuplicateVideo({ sourceId: video._id })
    navigate(`/video/${id}`)
  }

  const handleDownloadMenuClick = (e) => {
    if (e.key === 'download_subtitles') {
      track('preview_subtitles_downloaded')
      downloadSubtitles()
    } else if (e.key.startsWith('download_scorm_package')) {
      track('preview_scorm_package_downloaded')
      downloadScormPackage(e.key.split('_').pop())
    }
  }

  const handleOptionalMenuClick = (e) => {
    if (e.key === 'duplicate_video') duplicateVideo()
    else if (e.key === 'translate_video') setIsTranslateVideoModalOpen(true)
  }

  const updateVideoComments = ({ comments }) => {
    setVideo({ ...video, comments })
  }

  const downloadMenuItems = [buildScormButton('1.2', isEnterprisePlan), buildScormButton('2004v4', isEnterprisePlan)]
  if (video.data?.subtitlesUrl)
    downloadMenuItems.unshift({ key: 'download_subtitles', icon: <FileTextOutlined />, label: 'Download subtitles' })

  const optionalMenuItems = [
    { key: 'duplicate_video', icon: <CopyOutlined />, label: 'Duplicate/copy video' },
    { key: 'translate_video', icon: <TranslationOutlined />, label: 'Translate video' },
  ]

  if (authStore.user.account.source?.provider === 'thinkific')
    optionalMenuItems.push({
      key: 'add_thinkific',
      icon: <PlusOutlined />,
      label: (
        <Popover
          key="thinkific"
          trigger="click"
          content={
            <>
              <h3>Add this video directly into course</h3>
              <ul style={{ maxWidth: 400 }}>
                <li>Open your Thinkific account by clicking a button below.</li>
                <li>Find your course</li>
                <li>Click "Add lesson" and choose "Multimedia".</li>
                <li>Copy a link to your video file and paste to your lesson url.</li>
              </ul>
              <h3>Add this video to your Thinkific video library</h3>
              <ul style={{ maxWidth: 400 }}>
                <li>Open your video library by clicking a button below.</li>
                <li>Click "Upload" and choose "Link (URL)"</li>
                <li>Copy a link to your video file, paste to the input and click the button to save.</li>
              </ul>
              <Space>
                <Button
                  icon={<LinkOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(video.url)
                    message.success('Link copied')
                  }}
                >
                  Copy Link
                </Button>
                <Button
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={() =>
                    window
                      .open(
                        `https://${authStore.user.account.source?.thinkificSubdomain}.thinkific.com/manage/videos`,
                        '_blank',
                      )
                      .focus()
                  }
                >
                  Go To Thinkific
                </Button>
              </Space>
            </>
          }
        >
          <span>
            Add To <img src={thinkificLogo} style={{ height: 18, margin: '-3px 0 0 5px' }} />
          </span>
        </Popover>
      ),
    })

  if (video.url) {
    buttons.push(
      <Dropdown.Button
        type="primary"
        key="download_dropdown"
        href={video.url}
        icon={<DownloadOutlined />}
        onClick={() => track('preview_video_download')}
        menu={{ items: downloadMenuItems, onClick: handleDownloadMenuClick }}
      >
        Download
      </Dropdown.Button>,
    )
    buttons.push(
      <Popover
        key="share"
        trigger="click"
        placement="bottomRight"
        content={
          <Space direction="vertical">
            <Space>
              <label>Public</label>
              <Tooltip title="Anyone with the link will be able to access this video and you will be able to embed video anywhere.">
                <QuestionCircleOutlined />
              </Tooltip>
              <Switch checked={video.public} onChange={tooglePublic} />
            </Space>
            <Input.Group compact>
              <Input defaultValue={publicLink} style={{ width: '53%' }} />
              <Button
                icon={<LinkOutlined />}
                onClick={() => {
                  // if (!video.public) tooglePublic(true)
                  navigator.clipboard.writeText(publicLink)
                  message.success('Link copied')
                  track('preview_share_copy_link')
                }}
              >
                Copy Link
              </Button>
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={() => window.open(publicLink, '_blank').focus()}
              />
            </Input.Group>
            {video.public && (
              <Input.Group compact>
                <label>Embed video</label>
                <Input.TextArea rows={7} value={embedHtml} />
                <Button
                  type="primary"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(embedHtml)
                    message.success('Embed code copied to clipboard')
                    track('preview_share_copy_embed_code')
                  }}
                  style={{ float: 'right', marginTop: 5 }}
                >
                  Copy code
                </Button>
              </Input.Group>
            )}
          </Space>
        }
      >
        <Dropdown.Button
          key="optional_btn"
          icon={<MoreOutlined />}
          style={{ marginLeft: '12px' }}
          menu={{ items: optionalMenuItems, onClick: handleOptionalMenuClick }}
        >
          <LinkOutlined /> Share
        </Dropdown.Button>
      </Popover>,
    )
  }

  return (
    <Layout className="preview-main-layout" hasSider>
      <Content className="preview-main-content">
        <Card className="preview-main-card" bodyStyle={{ padding: 0 }}>
          <PageHeader
            title={video.name}
            onBack={() => goBack(() => navigate(`/video/${id}`))}
            style={{ padding: 15 }}
            extra={video.deleted ? null : buttons}
          />
          {!video.url ? (
            <Alert message={'Your video is not ready yet.'} type="error" />
          ) : (
            <>
              {video.status !== 'ready' && (
                <Alert
                  style={{ margin: 15 }}
                  message="This is a previous version of your video. The rendering for the updated video version is either underway or hasn't been started."
                  type="info"
                />
              )}
              <iframe
                ref={setIframeRef}
                src={videoSrc}
                title={dynamicTitle || 'Elai.io video player'}
                frameBorder="0"
                allowFullScreen
                scrolling="no"
              ></iframe>
            </>
          )}
        </Card>
      </Content>
      {isEnterprisePlan && (
        <Sider theme="light" width={320} className="preview-main-sider">
          <Comments video={video} defaultAnchorToSlides={false} updateVideo={updateVideoComments} />
        </Sider>
      )}
      <TranslateVideoModal video={video} isOpen={isTranslateVideoModalOpen} setIsOpen={setIsTranslateVideoModalOpen} />
    </Layout>
  )
}

export default Preview
