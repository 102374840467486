import { fabric } from 'fabric'
import { CANVAS_WIDTH } from '../../routes/video/slide/canvas/constans'

const Avatar = fabric.util.createClass(fabric.Image, {
  type: 'avatar',
  lockRotation: true,
  lockSkewingX: true,
  lockSkewingY: true,
  srcFromAttribute: false,
  lockScalingFlip: true,
  minScaleLimit: 0.07,

  initialize: function (image, options) {
    options.width = this.width
    options.height = this.height

    this.avatarType = options.avatarType
    this.src = image?.src
    this.image = image || new fabric.Image()
    this.tilt = options.tilt
    this.fill = options.fill
    this.animation = options.animation
    this.mainCanvas = new fabric.StaticCanvas(null, { enableRetinaScaling: false })
    this.circleImageCanvas = new fabric.StaticCanvas(null, { enableRetinaScaling: false })
    this.callSuper('initialize', this.mainCanvas.getElement(), options)
    this._draw()

    this.on({
      scaling: ({ transform }) => {
        if (transform.target.scaleX > this.maxScale) this.scale(this.maxScale)
      },
      moving: (v) => {
        const object = v.transform.target
        if (640 - object.getScaledWidth() / 2 < object.left) {
          object.set({ left: 640 - object.getScaledWidth() / 2 })
        } else if (-object.getScaledWidth() / 2 > object.left) {
          object.set({ left: -object.getScaledWidth() / 2 })
        }
        if (360 - object.getScaledHeight() / 2 < object.top) {
          object.set({ top: 360 - object.getScaledHeight() / 2 })
        } else if (-object.getScaledHeight() / 2 > object.top) {
          object.set({ top: -object.getScaledHeight() / 2 })
        }
        object.setCoords()
      },
    })
    this.setControlsVisibility({ mt: false, ml: false, mr: false, mb: false, mtr: false })
  },

  _draw: function () {
    if (this.mainCanvas.isDisposed()) return

    this.mainCanvas.clear()
    this.circleImageCanvas.clear()

    if (this.avatarType === 'voiceover') {
      this.visible = false
      this.hasControls = false
      this.hasBorders = false
    } else {
      this.visible = true
      this.hasControls = true
      this.hasBorders = true
    }

    this.height = 1080

    if (!this.image.width || !this.image.height) {
      return this.mainCanvas.renderAllSafe()
    }

    if (this.avatarType === 'transparent') {
      this.maxScale = 0.5
      this.image.scaleToHeight(this.height)
      this.width = this.image.getScaledWidth()
      this.mainCanvas.setDimensions({ width: this.width, height: this.height })
      this.mainCanvas.add(this.image)
    } else if (this.avatarType === 'circle') {
      this.maxScale = 0.3
      this.width = 1080
      this.mainCanvas.setDimensions({ width: this.width, height: this.height })
      this._createCircleAvatar()
    }

    if (this.scaleX > this.maxScale) this.scale(this.maxScale)
    else if (this.scaleX < this.minScaleLimit) this.scale(this.minScaleLimit)

    this.mainCanvas.renderAllSafe()
  },

  _createCircleAvatar: function () {
    const zoom = this.tilt?.zoom || 1.3
    const imageWidth = this.width * zoom
    this.image.scaleToWidth(imageWidth)
    this.circleImageCanvas.add(this.image)
    this.circleImageCanvas.setDimensions({ width: imageWidth, height: this.image.getScaledHeight() })
    this.circleImageCanvas.renderAllSafe()
    this.circleBackground = new fabric.Circle({ radius: this.width / 2, fill: this.fill })

    const circle = new fabric.Circle({ radius: this.width / 2 })
    circle.set(
      'fill',
      new fabric.Pattern({
        source: this.circleImageCanvas.getElement(),
        repeat: 'no-repeat',
        offsetX: -this.width * ((zoom - 1) / 2) + this.width * (this.tilt?.left || 0),
        offsetY: this.width * (this.tilt?.top || 0),
      }),
    )
    this.mainCanvas.add(this.circleBackground)
    this.mainCanvas.add(circle)
  },

  set: function (key, value) {
    if (typeof key === 'object') this._setObject(key)
    else this._set(key, value)
    if (key === 'avatarType') {
      this._draw()
    } else if (key === 'color') {
      this.fill = value
      this.circleBackground.set('fill', this.fill)
      this.mainCanvas.renderAllSafe()
    }
    return this
  },

  setAsync: async function (key, value) {
    this._set(key, value)
    if (key === 'presenter') {
      this.src = value.url
      this.tilt = value.tilt
      this.image = await fabric.imageFromURL(this.src)
      if (this.avatarType === 'voiceover' && value.avatarType !== 'voiceover') {
        this.canvas.bringToFront(this)
        if (value.videoFormat && value.videoFormat !== '16_9') {
          const left = CANVAS_WIDTH / 2 - this.getScaledWidth() / 2
          this.set({ left })
        }
      }
      if (value.avatarType) this.avatarType = value.avatarType
      this._draw()
      this.canvas.requestRenderAll()
    }
    return this
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      avatarType: this.avatarType,
      src: this.src,
      tilt: this.tilt,
      animation: this.animation,
    })
  },
})

Avatar.fromObject = async function (object, callback) {
  fabric
    .imageFromURL(object.src)
    .then((img) => callback(new fabric.Avatar(img, object)))
    .catch((e) => callback(new fabric.Avatar(null, object)))
}

export default Avatar
