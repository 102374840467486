import { Tooltip, Button } from 'antd'
import { useEffect, useState } from 'react'
import { SyncOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { useStore } from '../../store'
import { ADD_VIDEO_ICON, SAVE_OUTLINED_ICON, THUMBNAIL_MODES } from './constants'
import { request } from '../../utils/api'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'

export const useTemplatesCreation = ({ video, setActiveObjectModifier, saveVideo, updateVideo, canvasRegistry }) => {
  const navigate = useNavigate()
  const notification = useElaiNotification()

  const [isVideoCreating, setIsVideoCreating] = useState(false)
  const [isProcessingTemplate, setIsProcessingTemplate] = useState(false)

  const videosStore = useStore((stores) => stores.videosStore)

  const requestPublishTemplate = async () => {
    setIsProcessingTemplate(true)
    setActiveObjectModifier('discardActiveObject')

    await saveVideo()

    notification.success({
      key: 'publishing',
      duration: false,
      icon: <SyncOutlined spin />,
      message: 'Please wait while we are creating screenshots of your slides...',
    })

    // then generate thumbnails and publish
    await canvasRegistry.updateCanvases(video.slides, { thumbnailMode: THUMBNAIL_MODES.TEMPLATE })
  }

  const sendThumbnailsAndPublishTemplate = async () => {
    const { slides } = video
    const thumbnails = []
    try {
      slides.forEach((s) => {
        const thumbnail = canvasRegistry.templateThumbnails[s.id]
        thumbnails.push({
          slideId: s.id,
          image: thumbnail.toDataURL ? thumbnail.toDataURL('image/jpeg', 0.8) : thumbnail.src,
        })
      })
    } catch {
      setIsProcessingTemplate(false)
      await notification.destroy('publishing')
      return notification.error({
        duration: 3,
        message: 'Something went wrong while generating thumbnails. Please try again or contact support.',
      })
    }
    const res = await request({
      method: 'patch',
      url: `/videos/${video._id}/publish`,
      data: thumbnails,
    })
    canvasRegistry.clearTemplateThumbnails()
    if (!res) {
      await notification.destroy('publishing')
      setIsProcessingTemplate(false)
      return
    }

    const updatedTemplate = await videosStore.fetchTemplate(video._id)
    updateVideo(updatedTemplate, { replaceState: true, ignoreHistory: true })
    setIsProcessingTemplate(false)
    await notification.destroy('publishing')
    notification.success({
      duration: 5,
      message: 'Template was successfully published. Now you can use it to create videos.',
    })
  }

  // there is a problem with state syncronization, so we need to wait for templateThumbnailsReady
  // to be true before we can send thumbnails and publish template
  useEffect(() => {
    if (!canvasRegistry.templateThumbnailsReady) return

    sendThumbnailsAndPublishTemplate()
  }, [canvasRegistry.templateThumbnailsReady])

  const createVideoFromTemplate = async () => {
    setIsVideoCreating(video._id)
    const videoId = await requestDuplicateVideo({ sourceId: video._id })
    setIsVideoCreating(false)
    if (!videoId) return
    // TODO: why react redirect does't work? this need to be fixed
    navigate(`/video/${videoId}`)
    window.location.reload()
  }
  const onSaveTemplateClick = () => {
    requestPublishTemplate()
    return true
  }

  const templateButtons = video?.template
    ? [
        <Tooltip key="create-video" title={`Create new ${video.template.type} video using this template`}>
          <Button type="primary" icon={ADD_VIDEO_ICON} loading={isVideoCreating} onClick={createVideoFromTemplate}>
            Create video
          </Button>
        </Tooltip>,
        <Tooltip key="publish" title="This is required to use the template" placement="topLeft">
          <Button
            type="primary"
            icon={SAVE_OUTLINED_ICON}
            onClick={onSaveTemplateClick}
            disabled={!canvasRegistry.thumbnailsReady}
            loading={isProcessingTemplate}
          >
            {video.template.enabled ? 'Republish' : 'Publish'}
          </Button>
        </Tooltip>,
      ]
    : null

  return { templateButtons }
}
